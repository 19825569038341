import { useAuth0 } from "@auth0/auth0-react";

function CancelSubModal(props) {
  const { getAccessTokenSilently } = useAuth0();

  return (
    <>
      <div
        id="modal-js-example"
        className={"modal is-active"}
        style={{zIndex: 9999}}
      >
        <div className="modal-background" onClick={() => {props.setCancelSubModal(false)}}></div>

        <div
          className="modal-content"
          style={{ width: props.isMobile ? "90vw" : "auto", minWidth: "25vw" }}
        >
          <div className="box" style={{ textAlign: "center" }}>
            <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
            Are you sure you want to cancel your subscription?
            </p>
            <br />
            <p style={{fontSize: "110%", fontWeight: "700"}}>
            <a href="https://calendly.com/igprojects/nepeto-advanced-tutorial-call" style={{fontSize: '130%'}}>Schedule a FREE one-on-one call with a Nepeto expert!</a><br/>
            We will find <span style={{fontSize: "150%"}}>$200</span> in profit with you LIVE!<br/>
            You will discover Nepeto's full potential and features!<br/>
            Plus, <strong>enjoy 50% off</strong> the monthly/yearly plan after the call!<br/><br/>
            </p>
            <br />
            <div className="columns is-mobile is-centered">
              <button
                className="button is-rounded is-success"
                style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                onClick={() => {
                  props.setCancelSubModal(false);
                  window.location.href = "https://calendly.com/igprojects/nepeto-advanced-tutorial-call"
                }}
              >
                Book Now for Free
              </button>&emsp;
              <button
                className="button"
                style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw", border: "none" }}
                onClick={async () => {
                    const accessToken = await getAccessTokenSilently();
                    fetch("https://server.nepeto.com/cancel_subscription/", {
                      mode: "cors",
                      headers: { Authorization: `Bearer ${accessToken}` },
                    }).then((response) => response.json())
                      .then(() => {
                        window.location.reload();
                      });
                }}
              >
                Cancel Subscription
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CancelSubModal;
