import { useEffect, useState } from "react";
import ModalForSettings from "../Products/Components/ModalForSettings";
import { utils, writeFile } from 'xlsx';

function FilterBar(props) {
  const [modalSetting, setModalSetting] = useState("");
  const [searchText, setSearchText] = useState(props.filters.hasOwnProperty("search") ? props.filters.search : "");
  const [moreSettingsModal, setMoreSettingsModal] = useState(false);
  const [couponModal, setCouponModal] = useState(false);
  const [bsrType, setBsrType] = useState(false);
  const [categories, setCategories] = useState({
    "Kitchen & Dining": false,
    "Beauty & Personal Care": false,
    "Toys & Games": false,
    "Office Products": false,
    "Home & Kitchen": false,
    "Baby": false,
    "Unique Finds": false,
    "Sports & Outdoors": false,
    "Patio, Lawn & Garden": false,
    "Arts, Crafts & Sewing": false,
    "Tools & Home Improvement": false,
    "Automotive": false,
    "Industrial & Scientific": false,
    "Video Games": false,
    "Health & Household": false,
    "Our Brands": false,
    "Cell Phones & Accessories": false,
    "Pet Supplies": false,
    "Books": false,
    "Musical Instruments": false,
    "Clothing, Shoes & Jewelry": false,
    "Grocery & Gourmet Food": false,
    "Climate Pledge Friendly": false,
    "Electronics": false,
    "Amazon Renewed": false,
    "Amazon Devices & Accessories": false,
    "Computers & Accessories": false,
    "Appliances": false,
    "CDs & Vinyl": false,
    "Software": false,
    "Handmade Products": false,
    "Collectible Coins": false,
    "Movies & TV": false,
    "Camera & Photo Products": false,
  });
  const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
  const [gatedType, setGatedType] = useState(false);
  const [searchTextIndex, setSearchTextIndex] = useState(0);
  const [saveFiltersSlot, setSaveFiltersSlot] = useState(1);
  const [manualRender, setManualRender] = useState(15);
  const searchTextsPlaceholders = ['', 'P', 'Pr', 'Pro', 'Prod', 'Produ', 'Produc', 'Product', 'Product.', 'Product..', 'Product...',
    '', 'A', 'AS', 'ASI', 'ASIN', 'ASIN.', 'ASIN..', 'ASIN...',
    '', 'B', 'Br', 'Bra', 'Bran', 'Brand', 'Brand.', 'Brand..', 'Brand...']

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSearchTextIndex((prevIndex) => (prevIndex + 1) % searchTextsPlaceholders.length);
    }, 450);

    return () => clearInterval(intervalId);
  });

  const exportToExcel = () => {
    let formedData = []
    props.data.forEach(product => {
      formedData.push({
        "1. ASIN": product.asin,
        "2. UPC": '="' + (product.Barcode ? product.Barcode : product.upcs) + '"',
        "3. Name": product.title,
        "4. Category": product.category,
        "5. BSR": product.sales_rank,
        "6. AZ Offers": product.total_offers_count,
        "7. Supplier Price": product.price,
        "8. AZ Price": product.az_price,
        "9. FBA Cost": product.fba_fee,
        "10. AZ Referral Fee": product.az_price * product.ref_fee_perc,
        "11. ROI": (((product.az_price - product.price - (product.ref_fee_perc * product.az_price) - (!props.filters.isFBM && product.fba_fee) -
          parseFloat(localStorage.getItem("costPrep"))) / (product.price + parseFloat(localStorage.getItem("costPrep")))) * 100).toFixed(2),
        "12. Estimated Sales": product.keepa_monthly_sold > 0 ? ("Verified: " + product.keepa_monthly_sold + "+") : product.estimate_sales_30_days,
        "13. Supplier Link": product.url,
        "14. Amazon Link": "https://www.amazon.com/dp/" + product.asin,
        "15. Supplier Name": product.url.split("https://").pop().split("www.").pop().split(".c")[0],
      })
    });
    const ws = utils.json_to_sheet(formedData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet 1');
    writeFile(wb, 'Nepeto.csv');
  };

  return (
    <div
      className="columns is-centered is-multiline is-mobile"
      style={{
        textAlign: "center",
        marginTop: props.isMobile ? "1vh" : "8vh",
        marginLeft: "-2vw",
        marginRight: props.isMobile && ".5vw",
        verticalAlign: "middle",
      }}
      id="filterbarTt"
    >
      <div className="column is-2-desktop is-6-mobile">
        <div className="field has-addons">
          {!props.isMobile && <button
            className={"button is-rounded darkbutton"}
            style={{
              marginTop: "-5.5vh",
              marginLeft: ".2vw",
              backgroundColor: "#8B3C7E",
              color: "white",
              fontSize: props.isMobile ? "100%" : "0.85vw",
              position: "absolute",
            }}
            id="exportTt"
            onClick={() => {
              if (props.activePlanLevel !== "Pro Plan" && props.activePlanLevel !== "Master Plan") {
                alert("Only Pro & Master Plan subscribers can access this feature!")
              }
              else {
                exportToExcel()
              }
            }}
          >
            Export &nbsp;<i class="fa fa-download" style={{ paddingTop: "5px" }} aria-hidden="true"></i>
          </button>}
          {!props.isMobile && <label class="checkbox" style={{
            marginTop: "-4.5vh",
            marginLeft: "7.5vw",
            fontSize: props.isMobile ? "100%" : "0.85vw",
            fontWeight: "700",
            position: "absolute"
          }}>
            <input
              type="checkbox"
              style={{ transform: "scale(1.2)" }}
              onChange={(e) =>
                props.setFilters((filters) => ({
                  ...filters,
                  amazonNotSeller: +e.target.checked,
                }))
              }
              checked={props.filters.amazonNotSeller || false}
            />
            &nbsp;&nbsp;Filter out if Amazon is one of the sellers
          </label>}
          {!props.isMobile && <label class="checkbox tooltip" style={{
            marginTop: "-4.5vh",
            marginLeft: "26.1vw",
            fontSize: props.isMobile ? "100%" : "0.85vw",
            position: "absolute"
          }}>
            <input
              type="checkbox"
              style={{ transform: "scale(1.2)" }}
              onChange={(e) =>
                props.setFilters((filters) => ({
                  ...filters,
                  noVariations: +e.target.checked,
                }))
              }
              checked={props.filters.noVariations || false}
            />
            &nbsp;&nbsp;Must have no variations
          </label>}
          {!props.isMobile && <label class="checkbox tooltip" style={{
            marginTop: "-4.5vh",
            marginLeft: "37.6vw",
            fontSize: props.isMobile ? "100%" : "0.85vw",
            position: "absolute"
          }}>
            <input
              type="checkbox"
              style={{ transform: "scale(1.2)" }}
              onChange={(e) => props.setFilters((filters) => ({
                ...filters,
                offers: (props.filters.hasOwnProperty("offers") && props.filters['offers'][0] >= 4) ? [-1, -1] : [4, -1],
              }))}
              checked={props.filters.hasOwnProperty("offers") && props.filters['offers'][0] >= 4}
            />
            &nbsp;&nbsp;No Private Label<span style={{ fontSize: "62%" }}>(?)</span>
            <span class="tooltiptext">
              The listing must have 4+ AZOffers.
            </span>
          </label>}
          {!props.isMobile && <label class="checkbox tooltip" style={{
            marginTop: "-4.5vh",
            marginLeft: "47vw",
            fontSize: props.isMobile ? "100%" : "0.85vw",
            position: "absolute"
          }}>
            <input
              type="checkbox"
              style={{ transform: "scale(1.2)" }}
              onChange={(e) =>
                props.setFilters((filters) => ({
                  ...filters,
                  noMultiPacks: +e.target.checked,
                }))
              }
              checked={props.filters.noMultiPacks || false}
            />
            &nbsp;&nbsp;Filter out Multi-Packs
          </label>}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            width={props.isMobile ? "17vw" : "3.5vw"}
            height={"4.4vh"}
            viewBox="0 0 120.000000 120.000000"
            preserveAspectRatio="xMidYMid meet"
            style={{ cursor: "pointer" }}
            onClick={() => props.setFilters({})}
          >
            <g
              transform="translate(0.000000,111.000000) scale(0.0900000,-0.086000)"
              fill="#000000"
              stroke="none"
            >
              <path d="M813 1266 c-77 -25 -153 -94 -183 -167 l-12 -29 -247 0 c-266 0 -291 -5 -291 -53 0 -27 -6 -17 184 -277 l151 -205 5 -253 c5 -236 6 -255 24 -268 32 -23 50 -17 142 51 63 46 92 74 101 97 7 20 12 97 12 199 l1 166 58 82 57 82 51 -6 c30 -3 74 1 108 10 270 71 306 430 56 555 -56 28 -157 36 -217 16z m182 -82 c85 -40 145 -146 129 -231 -33 -185 -248 -257 -378 -127 -107 107 -78 286 56 353 52 26 145 28 193 5z m-384 -251 c8 -64 45 -130 99 -176 l40 -34 -18 -24 c-11 -13 -40 -55 -66 -92 l-46 -68 0 -178 0 -179 -55 -41 c-30 -22 -57 -41 -60 -41 -3 0 -5 96 -5 213 0 185 -2 217 -18 247 -10 19 -82 122 -160 228 -78 106 -142 195 -142 198 0 2 96 3 213 2 l212 -3 6 -52z" />
              <path d="M800 1070 c-8 -15 -5 -25 20 -53 l29 -34 -24 -23 c-28 -26 -32 -61 -11 -79 17 -14 41 -5 70 28 l19 21 34 -30 c28 -25 38 -28 53 -20 27 15 25 39 -7 72 l-27 28 27 28 c29 31 34 51 15 70 -18 18 -33 15 -68 -14 l-31 -27 -28 27 c-32 31 -56 33 -71 6z" />
            </g>
          </svg>

          <div className="control">
            <input
              className="input searchproduct"
              type="text"
              style={{
                backgroundColor: "rgb(179,127,170)",
                color: "white",
                fontSize: props.isMobile ? "1.9vh" : ".95vw",
              }}
              value={searchText}
              placeholder={"Search " + searchTextsPlaceholders[searchTextIndex]}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
          <div className="control">
            <button
              className="button is-success"
              style={{
                color: "white",
                fontSize: props.isMobile ? "1.9vh" : ".95vw",
              }}
              onClick={() => {
                props.setFilters((filters) => ({
                  ...filters,
                  search: searchText,
                }));
              }}
            >
              <i className="fa fa-search" />
            </button>
          </div>
        </div>
      </div>
      <div className="column is-1-desktop is-3-mobile">
        <details className="selectlight selectdropdown" style={{
          fontSize: props.isMobile ? "1.9vh" : ".85vw", border:
            props.filters &&
            props.filters.hasOwnProperty("category") &&
            "3px solid rgb(72,199,142)",
        }}>
          <div className="modal-background" onClick={(e) => {
            if (categoryDropdownOpen) {
              props.setFilters((filters) => ({
                ...filters,
                category: Object.keys(categories).sort().filter(key => categories[key] === true),
              }))
              if (Object.keys(categories).sort().filter(key => categories[key] === true).length === 0) {
                let tempFilters = { ...props.filters }
                delete tempFilters['category']
                props.setFilters(tempFilters)
              }
            }
            setCategoryDropdownOpen(before => !before);; e.target.parentElement.open = false
          }} style={{ zIndex: 9, height: "200vh", backgroundColor: "transparent" }}></div>
          <summary onClick={() => {
            if (categoryDropdownOpen) {
              props.setFilters((filters) => ({
                ...filters,
                category: Object.keys(categories).sort().filter(key => categories[key] === true),
              }))
              if (Object.keys(categories).sort().filter(key => categories[key] === true).length === 0) {
                let tempFilters = { ...props.filters }
                delete tempFilters['category']
                props.setFilters(tempFilters)
              }
            }
            setCategoryDropdownOpen(before => !before);
          }} style={{ backgroundColor: categoryDropdownOpen && "green", color: categoryDropdownOpen && "white", zIndex: 10, position: "relative" }}>{categoryDropdownOpen ? "Save" : "Category"}</summary>
          <ul>
            <li key={"SelectAll"}>
              <label><input type="checkbox" name={"SelectAll"} value={"SelectAll"}
                onChange={(e) => {
                  setCategories((prevCat) => {
                    const updatedCategories = { ...prevCat };

                    Object.keys(updatedCategories).forEach((key) => {
                      updatedCategories[key] = e.target.checked;
                    });

                    return updatedCategories;
                  })
                }} /> {Object.values(categories).every(value => value === true) ? "Unselect All" : "Select All"}</label>
            </li>
            {Object.keys(categories).sort().map((category, index) => {
              return <li key={index}>
                <label><input type="checkbox" name={category} value={category} checked={categories[category]}
                  onChange={(e) => { setCategories({ ...categories, [category]: e.target.checked }) }} /> {category}</label>
              </li>;
            })}
          </ul>
        </details>
      </div>
      <div className="column is-1-desktop is-3-mobile">
        <button
          className="button is-rounded lightbutton"
          onClick={() => setModalSetting("profit")}
          style={{
            border:
              props.filters &&
              props.filters.hasOwnProperty("profit") &&
              "3px solid rgb(72,199,142)",
          }}
        >
          Profit
        </button>
      </div>
      <div className="column is-1-desktop is-3-mobile">
        <button
          className="button is-rounded lightbutton"
          onClick={() => setModalSetting("roi")}
          style={{
            border:
              props.filters &&
              props.filters.hasOwnProperty("roi") &&
              "3px solid rgb(72,199,142)",
          }}
        >
          ROI
        </button>
      </div>
      <div className="column is-1-desktop is-3-mobile">
        <button
          className="button is-rounded lightbutton"
          onClick={() => setBsrType(true)}
          style={{
            border:
              props.filters &&
              (props.filters.hasOwnProperty("bsr") ||
                props.filters.hasOwnProperty("top_bsr")) &&
              "3px solid rgb(72,199,142)",
          }}
        >
          BSR
        </button>
        <div
          id="modal-js-example"
          className={
            "modal" + (bsrType ? " is-active" : "")
          }
        >
          <div className="modal-background" onClick={() => {
            setBsrType(false);
          }}></div>
          <div
            className="modal-content"
            style={{ width: props.isMobile ? "90vw" : "30vw" }}
          >
            <div className="box" style={{ textAlign: "center" }}>
              <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
                BSR Type
              </p>
              <br />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <button
                  className="button is-rounded lightbutton tooltip"
                  onClick={() => { setModalSetting("bsr"); setBsrType(false); }}
                  style={{
                    border:
                      props.filters &&
                      props.filters.hasOwnProperty("bsr") &&
                      "3px solid rgb(72,199,142)",
                    marginRight: "1vw"
                  }}
                >
                  Sales Rank #
                  <span class="tooltiptext" style={{ padding: "0%" }}>
                    Sales Rank Number
                  </span>
                </button>
                <button
                  className="button is-rounded lightbutton tooltip"
                  onClick={() => { setModalSetting("top_bsr"); setBsrType(false); }}
                  style={{
                    border:
                      props.filters &&
                      props.filters.hasOwnProperty("top_bsr") &&
                      "3px solid rgb(72,199,142)",
                  }}
                >
                  Top BSR %
                  <span class="tooltiptext" style={{ padding: "0%" }}>
                    % / Max Sales Rank
                  </span>
                </button>
              </div>
              <br /><br />
              <div className="columns is-mobile is-centered">
                <button
                  className="button is-rounded is-danger"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setBsrType(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="column is-1-desktop is-3-mobile">
        <button
          className="button is-rounded lightbutton"
          onClick={() => setModalSetting("offers")}
          style={{
            border:
              props.filters &&
              props.filters.hasOwnProperty("offers") &&
              "3px solid rgb(72,199,142)",
          }}
        >
          AZOffers
        </button>
      </div>
      <div className="column is-1-desktop is-3-mobile">
        <button
          className="button is-rounded lightbutton"
          onClick={() => setModalSetting("bsr_drops_30_days")}
          style={{
            border:
              props.filters &&
              props.filters.hasOwnProperty("bsr_drops_30_days") &&
              "3px solid rgb(72,199,142)",
          }}
        >
          BSR Drops
        </button>
      </div>
      <div className="column is-1-desktop is-3-mobile">
        <button
          className="button is-rounded lightbutton"
          onClick={() => setModalSetting("sales")}
          style={{
            border:
              props.filters &&
              props.filters.hasOwnProperty("sales") &&
              "3px solid rgb(72,199,142)",
          }}
        >
          Est. Sales
        </button>
      </div>
      <div className="column is-1-desktop is-3-mobile">
        <button
          className="button is-rounded lightbutton"
          onClick={() => setGatedType(true)}
          style={{
            border:
              props.filters &&
              (props.filters.hasOwnProperty("filterOutGated") ||
                props.filters.hasOwnProperty("showOnlyUngated")) &&
              "3px solid rgb(72,199,142)",
          }}
        >
          Gated
        </button>
        <div
          id="modal-js-example"
          className={
            "modal" + (gatedType ? " is-active" : "")
          }
        >
          <div className="modal-background" onClick={() => {
            setGatedType(false);
          }}></div>
          <div
            className="modal-content"
            style={{ width: props.isMobile ? "90vw" : "30vw" }}
          >
            <div className="box" style={{ textAlign: "center" }}>
              <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
                Filter by Gated Status
              </p>
              <br /><br />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <label class="checkbox tooltip" style={{
                  fontSize: props.isMobile ? "100%" : "0.9vw",
                }}>
                  <input
                    type="checkbox"
                    style={{ transform: "scale(1.4)" }}
                    onChange={(e) => props.setFilters((filters) => ({
                      ...filters,
                      filterOutGated: (props.filters.hasOwnProperty("filterOutGated") ? !props.filters['filterOutGated'] : true),
                    }))}
                    checked={props.filters.hasOwnProperty("filterOutGated") && props.filters['filterOutGated']}
                  />
                  &nbsp;&nbsp;Filter out Gated ASINs<br />
                  <i
                    className="fa fa-lock"
                    style={{
                      width: "2vw",
                      height: "2vw",
                      borderRadius: "20px",
                      border: "2px solid gray",
                      padding: "0.3vw 0.2vw",
                      fontSize: "1.4vw",
                      color: "white",
                      backgroundColor: "red",
                      marginTop: "0.25vw"
                    }} />
                  <span style={{ position: "absolute", fontSize: "2.75vw", marginLeft: "-1.8vw", marginTop: "-0.65vw" }}>X</span>
                  <i
                    className="fa fa-unlock-alt"
                    style={{
                      width: "2vw",
                      height: "2vw",
                      borderRadius: "20px",
                      border: "2px solid gray",
                      padding: "0.3vw 0.2vw",
                      fontSize: "1.4vw",
                      color: "white",
                      backgroundColor: "orange"
                    }} />
                  <i
                    className="fa fa-unlock"
                    style={{
                      width: "2vw",
                      height: "2vw",
                      borderRadius: "20px",
                      border: "2px solid gray",
                      padding: "0.3vw 0.2vw",
                      fontSize: "1.4vw",
                      color: "white",
                      backgroundColor: "green"
                    }} /><br /><span style={{ color: "green" }} className="tooltip">*Recommended<span style={{ fontSize: "75%" }}>(?)</span><span className="tooltiptext" style={{ height: "auto", padding: "0px", marginTop: "-10%" }}>See auto-approval and ungating opportunities</span></span>{" "}
                </label>
                <label class="checkbox tooltip" style={{
                  fontSize: props.isMobile ? "100%" : "0.9vw",
                  marginLeft: "auto",
                }}>
                  <input
                    type="checkbox"
                    style={{ transform: "scale(1.4)" }}
                    onChange={(e) => {
                      props.setFilters((filters) => ({
                        ...filters,
                        showOnlyUngated: (props.filters.hasOwnProperty("showOnlyUngated") ? !props.filters['showOnlyUngated'] : true),
                      }))
                    }}
                    checked={props.filters.hasOwnProperty("showOnlyUngated") && props.filters['showOnlyUngated']}
                  />
                  &nbsp;&nbsp;Show Only Ungated ASINs<br /><i
                    className="fa fa-lock"
                    style={{
                      width: "2vw",
                      height: "2vw",
                      borderRadius: "20px",
                      border: "2px solid gray",
                      padding: "0.3vw 0.2vw",
                      fontSize: "1.4vw",
                      color: "white",
                      backgroundColor: "red",
                      marginTop: "0.25vw"
                    }} />
                  <span style={{ position: "absolute", fontSize: "2.75vw", marginLeft: "-1.8vw", marginTop: "-0.65vw" }}>X</span>
                  <i
                    className="fa fa-unlock-alt"
                    style={{
                      width: "2vw",
                      height: "2vw",
                      borderRadius: "20px",
                      border: "2px solid gray",
                      padding: "0.3vw 0.2vw",
                      fontSize: "1.4vw",
                      color: "white",
                      backgroundColor: "orange"
                    }} />
                  <span style={{ position: "absolute", fontSize: "2.75vw", marginLeft: "-1.8vw", marginTop: "-0.65vw" }}>X</span>
                  <i
                    className="fa fa-unlock"
                    style={{
                      width: "2vw",
                      height: "2vw",
                      borderRadius: "20px",
                      border: "2px solid gray",
                      padding: "0.3vw 0.2vw",
                      fontSize: "1.4vw",
                      color: "white",
                      backgroundColor: "green"
                    }} />{" "}
                </label>
              </div>
              <br /><br />
              <span style={{ width: "70%", color: "black", display: "block", margin: "auto", fontWeight: "700" }}>
                *This filter removes gated ASINs from the page results (averaging 50). If you're gated on many products, you may see fewer results per page
              </span>
              <br /><br />
              <div className="columns is-mobile is-centered">
                <button
                  className="button is-rounded is-danger"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setGatedType(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!props.isMobile && <div className="column is-1-desktop" style={{ padding: "0", width: "auto", display: "table", verticalAlign: "middle" }}>
        &emsp;<span id="moreAdvTt" onClick={() => setMoreSettingsModal(true)} style={{
          textDecoration: "underline", width: "auto", cursor: "pointer", fontSize: "1.05vw", fontWeight: "500", display: "table-cell", verticalAlign: "middle",
          color: "SlateBlue",
        }}>More</span>
        <div
          id="modal-js-example"
          className={
            "modal" + (moreSettingsModal ? " is-active" : "")
          }
        >
          <div className="modal-background" onClick={() => {
            setMoreSettingsModal(false);
          }}></div>
          <div
            className="modal-content"
            style={{ width: props.isMobile ? "90vw" : "30vw" }}
          >
            <div className="box" style={{ textAlign: "center" }}>
              <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
                Advanced Settings
              </p>
              <br />
              <button
                className="button is-rounded lightbutton"
                onClick={() => { setCouponModal(true); }}
                style={{
                  border:
                    props.filters &&
                    props.filters.hasOwnProperty("priceCoupon") &&
                    "3px solid rgb(72,199,142)",
                  marginRight: "1vw"
                }}
              >
                Apply Coupon to Cost Price
              </button>
              <div className="column is-12-desktop is-12-mobile">
                <button
                  className="button is-rounded lightbutton"
                  onClick={() => setModalSetting("price")}
                  style={{
                    border:
                      props.filters &&
                      props.filters.hasOwnProperty("price") &&
                      "3px solid rgb(72,199,142)",
                  }}
                >
                  Price
                </button>
              </div>
              <br /><br />
              <div style={{ border: "1px solid gray", paddingTop: "15px", paddingBottom: "15px" }}>
                <div style={{ marginTop: "-5px", marginBottom: "7px" }}>
                  <button style={{ backgroundColor: "transparent", borderRadius: "20px", cursor: saveFiltersSlot !== 1 && "pointer" }} disabled={saveFiltersSlot === 1} onClick={() => setSaveFiltersSlot(1)}>1</button>&emsp;
                  <button style={{ backgroundColor: "transparent", borderRadius: "20px", cursor: saveFiltersSlot !== 2 && "pointer" }} disabled={saveFiltersSlot === 2} onClick={() => setSaveFiltersSlot(2)}>2</button>&emsp;
                  <button style={{ backgroundColor: "transparent", borderRadius: "20px", cursor: saveFiltersSlot !== 3 && "pointer" }} disabled={saveFiltersSlot === 3} onClick={() => setSaveFiltersSlot(3)}>3</button>&emsp;
                  <button style={{ backgroundColor: "transparent", borderRadius: "20px", cursor: saveFiltersSlot !== 4 && "pointer" }} disabled={saveFiltersSlot === 4} onClick={() => setSaveFiltersSlot(4)}>4</button>&emsp;
                  <button style={{ backgroundColor: "transparent", borderRadius: "20px", cursor: saveFiltersSlot !== 5 && "pointer" }} disabled={saveFiltersSlot === 5} onClick={() => setSaveFiltersSlot(5)}>5</button>&emsp;
                  <button style={{ backgroundColor: "transparent", borderRadius: "20px", cursor: saveFiltersSlot !== 6 && "pointer" }} disabled={saveFiltersSlot === 6} onClick={() => setSaveFiltersSlot(6)}>6</button>&emsp;
                  <button style={{ backgroundColor: "transparent", borderRadius: "20px", cursor: saveFiltersSlot !== 7 && "pointer" }} disabled={saveFiltersSlot === 7} onClick={() => setSaveFiltersSlot(7)}>7</button>
                </div>
                <div style={{ display: "flex", margin: 'auto', justifyContent: "center" }}>
                  <button
                    className="button is-rounded lightbutton is-success"
                    onClick={() => {
                      localStorage.setItem('saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : ""), JSON.stringify(props.filters)); setManualRender(mr => mr + 1)
                    }}
                    style={{
                      width: "30%",
                      marginRight: "10px"
                    }}
                  >
                    Save Filters
                  </button>
                  <button
                    className="button is-rounded lightbutton"
                    onClick={() => {
                      localStorage.getItem('saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : "")) !== null && props.setFilters(JSON.parse(localStorage.getItem('saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : ""))));
                    }}
                    style={{
                      width: "30%",
                    }}
                  >
                    Load Filters
                  </button>
                </div>
                <p style={{ width: "60%", overflowY: "scroll", backgroundColor: "rgba(0,0,0,0.2)", minHeight: manualRender + "px", maxHeight: "10vh", textAlign: "center", margin: "auto", marginTop: "5px" }}>
                  {(localStorage.getItem('saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : "")) !== null ?
                    localStorage.getItem('saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : "")) : "").replace("{", "").replace("}", "").replaceAll('"', '').replaceAll(',', ', ').replace("search:,", "").replace("moq:[-1, -1],", "").toLowerCase().split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1).replace(":", ": ")).join(' ').replaceAll("-1", "N/A")}</p>
                <input render={saveFiltersSlot} placeholder={localStorage.getItem('name_saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : "")) ?? "Name the filters"} style={{ marginTop: "8px" }} onBlur={(e) => { localStorage.setItem('name_saveFilters' + (saveFiltersSlot !== 1 ? saveFiltersSlot : ""), e.target.value); e.target.value = "" }} />
              </div>
              <br />
              <label class="checkbox tooltip" style={{
                fontSize: props.isMobile ? "100%" : "0.85vw",
              }}>
                <input
                  type="checkbox"
                  style={{ transform: "scale(1.2)" }}
                  onChange={(e) => props.setFilters((filters) => ({
                    ...filters,
                    isFBM: filters.hasOwnProperty("isFBM") ? !filters.isFBM : true,
                  }))}
                  checked={!props.filters.hasOwnProperty("isFBM") || !props.filters.isFBM}
                />
                &nbsp;&nbsp;Include FBA Fees in AZFees
              </label>

              <br /><br /><br /><br />
              <div className="columns is-mobile is-centered">
                <button
                  className="button is-rounded is-danger"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setMoreSettingsModal(false)
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>}

      <ModalForSettings
        filters={props.filters}
        setFilters={props.setFilters}
        modalSetting={modalSetting}
        setModalSetting={setModalSetting}
        isMobile={props.isMobile}
        supplierType={"retail"}
      />

      {couponModal && (
        <div
          id="modal-js-example"
          className={
            "modal" + (couponModal ? " is-active" : "")
          }
        >
          <div className="modal-background" onClick={() => {
            setCouponModal(false);
          }}></div>
          <div
            className="modal-content"
            style={{ width: props.isMobile ? "90vw" : "38vw" }}
          >
            <div className="box" style={{ textAlign: "center" }}>
              <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
                Add Price Coupon Percentage
              </p>
              <p>
                Everything will be recalculated with this coupon. It will show you new products that now suit your criteria after including it.
              </p>
              <br />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <button
                  className="button is-rounded lightbutton"
                  onClick={() => {
                    props.setFilters((filters) => ({
                      ...filters,
                      priceCoupon: 0,
                    })); setCouponModal(false);
                  }}
                  style={{
                    border:
                      props.filters &&
                      props.filters.hasOwnProperty("priceCoupon") &&
                      props.filters["priceCoupon"] === 0 &&
                      "3px solid rgb(72,199,142)",
                    marginRight: "1vw"
                  }}
                >
                  0%
                </button>
                <button
                  className="button is-rounded lightbutton"
                  onClick={() => {
                    props.setFilters((filters) => ({
                      ...filters,
                      priceCoupon: 5,
                    })); setCouponModal(false);
                  }}
                  style={{
                    border:
                      props.filters &&
                      props.filters.hasOwnProperty("priceCoupon") &&
                      props.filters["priceCoupon"] === 5 &&
                      "3px solid rgb(72,199,142)",
                    marginRight: "1vw"
                  }}
                >
                  5%
                </button>
                <button
                  className="button is-rounded lightbutton"
                  onClick={() => {
                    props.setFilters((filters) => ({
                      ...filters,
                      priceCoupon: 10,
                    })); setCouponModal(false);
                  }}
                  style={{
                    border:
                      props.filters &&
                      props.filters.hasOwnProperty("priceCoupon") &&
                      props.filters["priceCoupon"] === 10 &&
                      "3px solid rgb(72,199,142)",
                    marginRight: "1vw"
                  }}
                >
                  10%
                </button>
                <button
                  className="button is-rounded lightbutton"
                  onClick={() => {
                    props.setFilters((filters) => ({
                      ...filters,
                      priceCoupon: 15,
                    })); setCouponModal(false);
                  }}
                  style={{
                    border:
                      props.filters &&
                      props.filters.hasOwnProperty("priceCoupon") &&
                      props.filters["priceCoupon"] === 15 &&
                      "3px solid rgb(72,199,142)",
                    marginRight: "1vw"
                  }}
                >
                  15%
                </button>
                <button
                  className="button is-rounded lightbutton"
                  onClick={() => {
                    props.setFilters((filters) => ({
                      ...filters,
                      priceCoupon: 20,
                    })); setCouponModal(false);
                  }}
                  style={{
                    border:
                      props.filters &&
                      props.filters.hasOwnProperty("priceCoupon") &&
                      props.filters["priceCoupon"] === 20 &&
                      "3px solid rgb(72,199,142)",
                    marginRight: "1vw"
                  }}
                >
                  20%
                </button>
                <button
                  className="button is-rounded lightbutton"
                  onClick={() => {
                    props.setFilters((filters) => ({
                      ...filters,
                      priceCoupon: 25,
                    })); setCouponModal(false);
                  }}
                  style={{
                    border:
                      props.filters &&
                      props.filters.hasOwnProperty("priceCoupon") &&
                      props.filters["priceCoupon"] === 25 &&
                      "3px solid rgb(72,199,142)",
                    marginRight: "1vw"
                  }}
                >
                  25%
                </button>
                <button
                  className="button is-rounded lightbutton"
                  style={{
                    border:
                      props.filters &&
                      props.filters.hasOwnProperty("priceCoupon") &&
                      ![0, 5, 10, 15, 20, 25].includes(props.filters["priceCoupon"]) &&
                      "3px solid rgb(72,199,142)",
                    marginRight: "1vw",
                    cursor: "default"
                  }}
                >
                  <input onBlur={(e) => {
                    if (e.target.value && e.target.value > 0) {
                      props.setFilters((filters) => ({
                        ...filters,
                        priceCoupon: parseFloat(e.target.value),
                      })); setCouponModal(false);
                    }
                  }} type="number" placeholder={props.filters && props.filters.hasOwnProperty("priceCoupon") && props.filters["priceCoupon"]} style={{ width: '2.5vw' }} />%
                </button>
              </div>
              <br /><br />
              <div className="columns is-mobile is-centered">
                <button
                  className="button is-rounded is-danger"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setCouponModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>)}
    </div>
  );
}

export default FilterBar;
