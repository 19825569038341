import React, { useEffect, useState } from "react";
import ModalForHide from "../Products/Components/ModalForHide";
import NotDirectLinkPopup, { warningSuppliers } from "../Products/Components/NotDirectLinkPopup";
import { signupDiscountSuppliers } from "../Products/Components/RegisterForDiscount";
import { OOSbyZIPSuppliers } from "../Products/Components/OOSbyZIP";
import MessageBeforeLinkPopup from "../Products/Components/MessageBeforeLinkPopup";
import FavoritesIcon from "../Products/Components/FavoritesIcon";
import ModalForVariationsTable from "../Products/Components/ModalForVariationsTable";
import AdvancedData from "../Products/Components/AdvancedData";
import logo from "../../logo2.svg";
import { useAuth0 } from "@auth0/auth0-react";

export default function ProductsTable(props) {
  const [hideModalProduct, setHideModalProduct] = useState("");
  const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0();
  const [linkUrl, setLinkUrl] = useState("");
  const [prep, setPrep] = useState(localStorage.getItem("costPrep") || 0);
  const [advancedDataProduct, setAdvancedDataProduct] = useState(null);
  const [showMessageObj, setShowMessageObj] = useState(null);
  const [ctrlClickTip, setCtrlClickTip] = useState("");
  const [searchModal, setSearchModal] = useState(null);
  const [saveMoney, setSaveMoney] = useState(null);
  const [saveMoneyRates, setSaveMoneyRates] = useState({});
  const [modalForVariations, setModalForVariations] = useState(false);
  const [modalForVariationsAsin, setModalForVariationsAsin] = useState("");
  const [gatedStatus, setGatedStatus] = useState({});
  const [loginWAZ, setLoginWAZ] = useState(false);
  const [headerStyle, setHeaderStyle] = useState({});
  const mainProductPage = ["big5sportinggoods", "dillards", "famousfootwear", "kohls", "dickssportinggoods", "boscovs", "jcpenney", "fivebelow", "nike", "belk", "underarmour", "crocs", "champssports", "sephora"]
  const categorySalesRankCount = { 'Climate Pledge Friendly': 1500000, 'Kindle Store': 6756566, 'Video Shorts': 127580, 'Apps & Games': 936100, 'Baby Products': 4172515, 'Digital Music': 68941656, 'Alexa Skills': 112529, 'Toys & Games': 9204843, 'Patio, Lawn & Garden': 22608266, 'Books': 102997339, 'Arts, Crafts & Sewing': 16296162, 'Software': 177161, 'Sports & Outdoors': 41416624, 'Gift Cards': 28409, 'Video Games': 1204647, 'Handmade Products': 3644844, 'Clothing, Shoes & Jewelry': 275101033, 'Office Products': 12266328, 'Grocery & Gourmet Food': 3869382, 'Tools & Home Improvement': 37997159, 'Movies & TV': 7740340, 'Musical Instruments': 2893522, 'Collectibles & Fine Art': 8802597, 'Appliances': 1565048, 'Pet Supplies': 8652837, 'Cell Phones & Accessories': 26169713, 'Baby': 4172515, 'Industrial & Scientific': 20388882, 'Everything Else': 8317014, 'CDs & Vinyl': 8366789, 'Beauty & Personal Care': 14890600, 'Home & Kitchen': 161989558, 'Electronics': 25856893, 'Automotive': 46255283, 'Magazine Subscriptions': 42578, 'Health & Household': 11049107, 'Audible Books & Originals': 743380, 'Kitchen & Dining': 37818304, "Amazon Devices": 1894, "Computers & Accessories": 14064999, "Camera & Photo Products": 4021127, "Collectible Coins": 210648, "Camera & Photo": 4021127 }

  const handleScroll = () => {
    try {
      const table = document.getElementById('tableTable');
      const tableRect = table.getBoundingClientRect();

      if (tableRect.top <= 0) {
        // The header is out of view (or at the top of the viewport)
        setHeaderStyle({
          position: 'relative',
          top: -tableRect.top + "px",
          zIndex: 9,
        });
      } else {
        setHeaderStyle({});
      }
    } catch { }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const checkGated = async (asin) => {
      if (props.sellerId) {
        await fetch(`https://server.nepeto.com/mobile_app_check_restrictions/`, {
          mode: "cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sellerId: props.sellerId,
            asin: asin,
            refreshToken: props.refreshToken,
          })
        }).then(response => response.json())
          .then(data => {
            setGatedStatus((prevGS) => ({
              ...prevGS,
              [asin]: data,
            }));
          })
      }
    };

    if (!isLoading && isAuthenticated && props.data && props.data.length > 0 && props.sellerId !== "none" && props.sellerId !== "") {
      props.data.sort((a, b) =>
        (props.newAsins && props.newAsins.includes(a.asin.split("/").pop().replace("B0", "")) ? -1 : 1) -
        (props.newAsins && props.newAsins.includes(b.asin.split("/").pop().replace("B0", "")) ? -1 : 1)
      ).slice((props.page - 1) * 10, props.page * 10).forEach(prd => { if (!gatedStatus.hasOwnProperty(prd.asin.split("/")[prd.asin.split("/").length - 1])) { checkGated(prd.asin.split("/")[prd.asin.split("/").length - 1]) } });
      setAdvancedDataProduct(null);
    }
    if (!isLoading && isAuthenticated && props.data && props.data.length > 0 && localStorage.getItem("azlog_pop_first") !== "1") {
      setLoginWAZ(true);
      localStorage.setItem("azlog_pop_first", "1");
    }
  }, [isLoading, isAuthenticated, props.data, props.page]);

  useEffect(() => {
    const getSaveMoneyRates = async () => {
      setSaveMoneyRates({})
      const accessToken = await getAccessTokenSilently();
      fetch(
        "https://server.nepeto.com/get_cashback_rates/" + saveMoney + "/", { mode: "cors", headers: { Authorization: `Bearer ${accessToken}` } }
      )
        .then((response) => response.json())
        .then((data) => {
          if (saveMoney !== null) {
            setSaveMoneyRates(data);
          }
        });
    };

    if (!isLoading && isAuthenticated && saveMoney !== null) {
      getSaveMoneyRates();
    }
  }, [getAccessTokenSilently, isLoading, isAuthenticated, saveMoney]);

  const openSuppLink = (product) => {
    if (product.source_name === "b2bsportswear") {
      product.url = product.url.replace("+", "").replace("--", "-")
    }
    if (!warningSuppliers.includes(product.source_name)) {
      window.open(
        product.url.replace(
          "X".repeat(product.source_name.length),
          product.source_name
        )
      );
    } else {
      setLinkUrl(
        product.url.replace(
          "X".repeat(product.source_name.length),
          product.source_name
        )
      );
    }
  };

  const reportMismatch = async (asin, source) => {
    const accessToken = await getAccessTokenSilently();
    fetch("https://server.nepeto.com/report_mismatch/" + asin + "/" + source + "/", {
      mode: "cors",
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  };

  return (
    <>
      <div className="table-container products-table-container">
        <table id="tableTable" className="table is-hoverable products-table">
          <thead style={headerStyle}>
            <tr>
              <th>Product</th>
              <th>Category</th>
              <th>
                <abbr
                  title="BSR"
                  style={{
                    display:
                      props.filters.hasOwnProperty("sortBy") &&
                      props.filters["sortBy"][0] === "sales_rank" &&
                      "flex",
                    textDecoration: props.setSortASC === undefined && "none"
                  }}
                  onClick={() => {
                    props.setSortASC(!props.sortASC);
                    props.setFilters((filters) => ({
                      ...filters,
                      sortBy: ["sales_rank", props.sortASC],
                    }));
                  }}
                >
                  BSR
                  {props.filters.hasOwnProperty("sortBy") &&
                    props.filters["sortBy"][0] === "sales_rank" && (
                      <i
                        className={
                          "fa fa-arrow-" +
                          (props.filters["sortBy"][1] ? "up" : "down")
                        }
                        aria-hidden="true"
                        style={{ marginTop: "5px" }}
                      ></i>
                    )}
                </abbr>
              </th>
              <th>ASIN</th>
              <th>
                <abbr
                  title="Price"
                  style={{
                    display:
                      props.filters.hasOwnProperty("sortBy") &&
                      props.filters["sortBy"][0] === "price" &&
                      "flex",
                    textDecoration: props.setSortASC === undefined && "none"
                  }}
                  onClick={() => {
                    props.setSortASC(!props.sortASC);
                    props.setFilters((filters) => ({
                      ...filters,
                      sortBy: ["price", props.sortASC],
                    }));
                  }}
                >
                  Price
                  {props.filters.hasOwnProperty("sortBy") &&
                    props.filters["sortBy"][0] === "price" && (
                      <i
                        className={
                          "fa fa-arrow-" +
                          (props.filters["sortBy"][1] ? "up" : "down")
                        }
                        aria-hidden="true"
                        style={{ marginTop: "5px" }}
                      ></i>
                    )}
                </abbr>
              </th>
              <th class="tooltip">AZPrice<span style={{ fontSize: "65%" }}>(?)</span>
                <span class="tooltiptext" style={{ marginTop: "-15%" }}>
                  Lowest New Price *Include Shipping
                  <img style={{ all: "unset", width: "8.5vw" }} loading="lazy" alt="azprice" src="/assets/azprice.png" />
                </span></th>
              <th class="tooltip">
                AZFees<span style={{ fontSize: "62%" }}>(?)</span>
                <span class="tooltiptext">
                  Amazon Referral Fee {!(props.filters.hasOwnProperty("isFBM") && props.filters.isFBM) && "+ Fulfillment Cost (FBA)"}
                </span>
              </th>
              <th class="tooltip" style={{ fontSize: "95%" }}>
                Costs<span style={{ fontSize: "62%" }}>(?)</span>
                <span class="tooltiptext">
                  Input any additional costs, such as prep cost, FBM, and
                  others.
                </span>
              </th>
              <th>AZOffers</th>
              <th>
                <abbr
                  title="Profit"
                  style={{
                    display:
                      props.filters.hasOwnProperty("sortBy") &&
                      props.filters["sortBy"][0] === "profit" &&
                      "flex",
                    textDecoration: props.setSortASC === undefined && "none"
                  }}
                  onClick={() => {
                    props.setSortASC(!props.sortASC);
                    props.setFilters((filters) => ({
                      ...filters,
                      sortBy: ["profit", props.sortASC],
                    }));
                  }}
                >
                  Profit
                  {props.filters.hasOwnProperty("sortBy") &&
                    props.filters["sortBy"][0] === "profit" && (
                      <i
                        className={
                          "fa fa-arrow-" +
                          (props.filters["sortBy"][1] ? "up" : "down")
                        }
                        aria-hidden="true"
                        style={{ marginTop: "5px" }}
                      ></i>
                    )}
                </abbr>
              </th>
              <th>
                <abbr
                  title="ROI"
                  style={{
                    display:
                      props.filters.hasOwnProperty("sortBy") &&
                      props.filters["sortBy"][0] === "roi" &&
                      "flex",
                    textDecoration: props.setSortASC === undefined && "none"
                  }}
                  onClick={() => {
                    props.setSortASC(!props.sortASC);
                    props.setFilters((filters) => ({
                      ...filters,
                      sortBy: ["roi", props.sortASC],
                    }));
                  }}
                >
                  ROI
                  {props.filters.hasOwnProperty("sortBy") &&
                    props.filters["sortBy"][0] === "roi" && (
                      <i
                        className={
                          "fa fa-arrow-" +
                          (props.filters["sortBy"][1] ? "up" : "down")
                        }
                        aria-hidden="true"
                        style={{ marginTop: "5px" }}
                      ></i>
                    )}
                </abbr>
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {(props.data.length === 0 || props.data.slice((props.page - 1) * 10, props.page * 10).length === 0) && props.isProductsLoading === false && <td style={{ textAlign: "center" }} colSpan={12}>No products.{props.data.length === 0 && " No scan credit used."} Have you tried our Live Search yet?</td>}
            {props.data.sort((a, b) =>
              (props.newAsins && props.newAsins.includes(a.asin.split("/").pop().replace("B0", "")) ? -1 : 1) -
              (props.newAsins && props.newAsins.includes(b.asin.split("/").pop().replace("B0", "")) ? -1 : 1)
            ).slice((props.page - 1) * 10, props.page * 10).map((product, index) => {
              if ((!props.filters.filterOutGated || (gatedStatus.hasOwnProperty(product.asin.split("/")[product.asin.split("/").length - 1]) && gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions ? (gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions.length === 0 ? "green" : (gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions[0].reasons[0].reasonCode === "APPROVAL_REQUIRED" ? "orange" : "red")) : "white") !== "red") &&
                (!props.filters.showOnlyUngated || (gatedStatus.hasOwnProperty(product.asin.split("/")[product.asin.split("/").length - 1]) && gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions ? (gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions.length === 0 ? "green" : (gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions[0].reasons[0].reasonCode === "APPROVAL_REQUIRED" ? "orange" : "red")) : "white") === "green")) {
                return (<>
                  <tr key={index}>
                    <td style={{ width: "22vw" }}>
                      <div style={{ display: "flex" }}>
                        <div className="amazonImage">
                          <p
                            onClick={() => {
                              window.open(product.asin + "?th=1&psc=1");
                            }}
                            style={{ textAlign: "center", cursor: "pointer" }}
                          >
                            Amazon
                            {props.newAsins && props.newAsins.includes(product.asin.split("/")[product.asin.split("/").length - 1].replace("B0", "")) && <span style={{ backgroundColor: "lightgreen", padding: "0% 5%", borderRadius: "30px", marginLeft: "5%" }}>NEW</span>}
                          </p>
                          <img
                            alt="product_image"
                            className="hoverImageTable"
                            loading="lazy"
                            src={(product.az_img !== null && product.az_img !== "") ?
                              product.az_img :
                              `https://images.amazon.com/images/P/${product.asin.split("/")[product.asin.split("/").length - 1]}.jpg`}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "/assets/noimage.png";
                            }}
                            style={{
                              borderRadius: "4.5vw",
                              border: "5px solid #E3D0DF",
                              height: "4.5vw",
                              width: "4.5vw",
                              objectFit: "scale-down",
                              position: "relative",
                              zIndex: "5"
                            }}
                            onMouseEnter={() => {
                              if (localStorage.getItem("CtrlClickTipCount") === null || localStorage.getItem("CtrlClickTipCount") < 15) {
                                setCtrlClickTip(product.asin);
                                localStorage.setItem("CtrlClickTipCount", localStorage.getItem("CtrlClickTipCount") === null ? 1 : parseInt(localStorage.getItem("CtrlClickTipCount")) + 1);
                              }
                            }}
                            onMouseLeave={() => setCtrlClickTip("")}
                            onClick={() => {
                              window.open(product.asin + "?th=1&psc=1");
                            }}
                          />
                          <i class="fa fa-external-link" title="Open the two links at the same time" aria-hidden="true" style={{ position: "absolute", left: "15.8vw", marginTop: "-1vw", cursor: "pointer" }} onClick={() => {
                            let message = "";
                            if (mainProductPage.includes(product.source_name)) {
                              message +=
                                "You will see the main product page.\nYou will need to set the correct variation yourself.\n'..." + product.title.split(" | At")[0].slice((product.title.split(" | At")[0].length / 2).toFixed(0)) + "'<br/>";
                            }
                            if (
                              signupDiscountSuppliers.includes(
                                product.source_name
                              )
                            ) {
                              message +=
                                "To receive the lowest price, this supplier requires you to sign up to their website.";
                            }
                            if (
                              OOSbyZIPSuppliers.includes(
                                product.source_name
                              )
                            ) {
                              message +=
                                "\nIf the product is out of stock - Change the ZIP CODE!\n";
                            }
                            if (message !== "" && (localStorage.getItem("showSuppMesssage" + product.source_name) === 'true' || localStorage.getItem("showSuppMesssage" + product.source_name) === null)) {
                              setShowMessageObj({
                                message: message,
                                messageAction: openSuppLink,
                                messageActionArg: product,
                                source: product.source_name
                              });
                            } else {
                              openSuppLink(product);
                            }
                            let secondOpened = window.open(product.asin + "?th=1&psc=1");
                            if (!secondOpened) {
                              alert("Please enable pop-ups in your browser to continue.")
                            }
                          }}></i>
                        </div>
                        <span
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "10vw",
                            height: "10vh",
                            padding: "9px",
                            textAlign: "center",
                            margin: "auto",
                            fontSize: "77.5%"
                          }}
                        >
                          {product.title.split(" | At")[0].slice(0, 40)}...{product.title.split(" | At")[0].slice(-25)}
                          <span className="tooltip" style={{ position: "absolute", marginLeft: "2px" }}>
                            <i class="fa fa-info-circle" id="variationTt" aria-hidden="true" style={{ fontSize: "90%" }}></i>
                            <span className="tooltiptext" style={{ zIndex: "999", padding: "5px" }}>
                              {product.title.split(" | At")[0]}
                            </span>
                          </span>
                        </span>
                        {ctrlClickTip === product.asin && <span style={{ position: "absolute", backgroundColor: "#E3D0DF", zIndex: "999", marginLeft: "6.5vw", padding: "5px", fontSize: "70%", marginTop: "10vh" }}>
                          Tip: Hold CTRL + Click.
                        </span>}
                        <div className="sourceImage">
                          <p
                            onClick={() => {
                              let message = "";
                              if (mainProductPage.includes(product.source_name)) {
                                message +=
                                  "You will see the main product page.\nYou will need to set the correct variation yourself.\n'..." + product.title.split(" | At")[0].slice((product.title.split(" | At")[0].length / 2).toFixed(0)) + "'<br/>";
                              }
                              if (
                                signupDiscountSuppliers.includes(
                                  product.source_name
                                )
                              ) {
                                message +=
                                  "To receive the lowest price, this supplier requires you to sign up to their website.";
                              }
                              if (
                                OOSbyZIPSuppliers.includes(
                                  product.source_name
                                )
                              ) {
                                message +=
                                  "\nIf the product is out of stock - Change the ZIP CODE!\n";
                              }
                              if (message !== "" && (localStorage.getItem("showSuppMesssage" + product.source_name) === 'true' || localStorage.getItem("showSuppMesssage" + product.source_name) === null)) {
                                setShowMessageObj({
                                  message: message,
                                  messageAction: openSuppLink,
                                  messageActionArg: product,
                                  source: product.source_name
                                });
                              } else {
                                openSuppLink(product);
                              }
                            }}
                            style={{
                              textAlign: "center",
                              maxWidth: "4.5vw",
                              cursor: "pointer",
                            }}
                          >
                            {product.source_name.replace("-", "").replace("fragrancenet", "fragrancenetWS")}
                          </p>
                          <img
                            alt="product_image"
                            className="hoverImageTable"
                            loading="lazy"
                            src={product.img}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "/assets/noimage.png";
                            }}
                            style={{
                              borderRadius: "4.5vw",
                              border: "5px solid #E3D0DF",
                              height: "4.5vw",
                              width: "4.5vw",
                              objectFit: "scale-down",
                              position: "relative",
                              zIndex: "5"
                            }}
                            onMouseEnter={() => {
                              if (localStorage.getItem("CtrlClickTipCount") === null || localStorage.getItem("CtrlClickTipCount") < 15) {
                                setCtrlClickTip(product.asin);
                                localStorage.setItem("CtrlClickTipCount", localStorage.getItem("CtrlClickTipCount") === null ? 1 : parseInt(localStorage.getItem("CtrlClickTipCount")) + 1);
                              }
                            }}
                            onMouseLeave={() => setCtrlClickTip("")}
                            onClick={() => {
                              let message = "";
                              if (mainProductPage.includes(product.source_name)) {
                                message +=
                                  "You will see the main product page.\nYou will need to set the correct variation yourself.\n'..." + product.title.split(" | At")[0].slice((product.title.split(" | At")[0].length / 2).toFixed(0)) + "'<br/>";
                              }
                              if (
                                signupDiscountSuppliers.includes(
                                  product.source_name
                                )
                              ) {
                                message +=
                                  "To receive the lowest price, this supplier requires you to sign up to their website.";
                              }
                              if (
                                OOSbyZIPSuppliers.includes(
                                  product.source_name
                                )
                              ) {
                                message +=
                                  "\nIf the product is out of stock - Change the ZIP CODE!\n";
                              }
                              if (message !== "" && (localStorage.getItem("showSuppMesssage" + product.source_name) === 'true' || localStorage.getItem("showSuppMesssage" + product.source_name) === null)) {
                                setShowMessageObj({
                                  message: message,
                                  messageAction: openSuppLink,
                                  messageActionArg: product,
                                  source: product.source_name
                                });
                              } else {
                                openSuppLink(product);
                              }
                            }}
                          />
                          {(product.source_name === "boscovs" || product.source_name === "dickssportinggoods" || product.source_name === "b2bsportswear" || product.source_name === "joann" || product.source_name === "rei") &&
                            <div className="warningSign" style={{ borderBottom: "2px solid rgba(0,155,0,0.5)" }}>
                              <i class="fa fa-info-circle" aria-hidden="true"></i>
                              <span className="warText"> Select the correct variation on {product.source_name}'s website.</span>
                            </div>}
                        </div>
                      </div>
                    </td>
                    <td id="categoryTt" style={{ color: !categorySalesRankCount[product.category] && "darkred" }}>{product.category}</td>
                    <td id="bsrTt" style={{ minWidth: "5.6vw", color: !categorySalesRankCount[product.category] && "darkred" }}>#{product.sales_rank}{categorySalesRankCount[product.category] && <><br /><span style={{ fontSize: "83%" }}>Top {((product.sales_rank / categorySalesRankCount[product.category]) * 100) < 0.01 ? "<0.01" : ((product.sales_rank / categorySalesRankCount[product.category]) * 100).toFixed(((product.sales_rank / categorySalesRankCount[product.category]) * 100) < 0.1 ? 2 : 1)}%</span></>}</td>
                    <td id="asinTt">
                      {props.newAsins && props.newAsins.includes(product.asin.split("/")[product.asin.split("/").length - 1].replace("B0", "")) && <span style={{ backgroundColor: "lightgreen", padding: "0% 5%", borderRadius: "30px" }}>NEW</span>}
                      <br />
                      <a
                        href={product.asin}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "underline", color: "#8B3C7E" }}
                      >
                        {
                          product.asin.split("/")[
                          product.asin.split("/").length - 1
                          ]
                        }
                      </a>
                      <button
                        className="graphicon"
                        id="variationBtnTt"
                        style={{
                          width: "calc(1.2vh + 4.55vw)",
                          height: "calc(1.2vh + .75vw)",
                          borderRadius: "20px",
                          border: "2px solid gray",
                          objectFit: "contain",
                          fontSize: ".65vw",
                          cursor: "pointer",
                          color: "rgb(139, 60, 126)",
                          fontWeight: "600",
                          marginBottom: "7px",
                          backgroundColor: "white",
                        }}
                        onClick={() => {
                          setModalForVariationsAsin(product.asin.split("/")[product.asin.split("/").length - 1]);
                          setModalForVariations(true)
                        }}
                      >VARIATIONS</button>
                    </td>
                    <td id="buyPriceTt" style={{ width: "4.5vw", maxWidth: "4.5vw", paddingTop: "2%" }} class="bg">
                      ${(product.price * (1 - (props.filters.hasOwnProperty("priceCoupon") && (props.filters["priceCoupon"] / 100)))).toFixed(2)}
                      <button
                        className="graphicon"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "20px",
                          border: "2px solid gray",
                          objectFit: "contain",
                          fontSize: "65%",
                          cursor: "pointer",
                          color: "rgb(139, 60, 126)",
                          fontWeight: "600",
                          marginBottom: "7px",
                          backgroundColor: "white",
                        }}
                        onClick={() => {
                          setSaveMoney(product.source_name)
                        }}
                      >Save $</button>
                    </td>
                    <td id="sellPriceTt" style={{ width: "6.5vw", maxWidth: "6.5vw" }} class="bg">
                      {product.az_price > 0 ? `$${product.az_price.toFixed(2)}` : "OOS on AMZ"}
                    </td>
                    <td id="azFeesTt">
                      {product.az_price > 0 ? (product.selling_on_az_fees === 0 || product.fba_cost === 0
                        ? "N/A"
                        : "-$" +
                        (product.selling_on_az_fees + (!props.filters.isFBM && product.fba_cost)).toFixed(
                          2
                        )) : "OOS on AMZ"}
                    </td>
                    <td id="costTt">
                      <input
                        type="number"
                        style={{ width: "3vw" }}
                        value={prep}
                        min={0}
                        step={0.25}
                        onChange={(e) => { setPrep(e.target.value); localStorage.setItem("costPrep", e.target.value) }}
                        onBlur={(e) => e.target.value === "" && setPrep(0)}
                      />
                    </td>
                    <td id="azOffersTt">
                      {JSON.parse(product.az_offers).length}
                      {JSON.parse(product.az_offers).length === 20 && "+"}{" "}
                      <i className="fa fa-user" aria-hidden="true"></i><br />
                      <span style={{ fontSize: "80%" }}>{`FBA: ${JSON.parse(product.az_offers).filter(item => item.is_fba).length}`}<br />
                        {`FBM: ${JSON.parse(product.az_offers).filter(item => !item.is_fba).length}`}</span>
                    </td>
                    <td>
                      {product.az_price > 0 ? `+$${(
                        product.az_price -
                        (product.price * (1 - (props.filters.hasOwnProperty("priceCoupon") && (props.filters["priceCoupon"] / 100)))) -
                        product.selling_on_az_fees -
                        (!props.filters.isFBM && product.fba_cost) -
                        parseFloat(prep)
                      ).toFixed(2)}` : "OOS on AMZ"}
                    </td>
                    <td class="bg" id="roiTt">
                      {product.az_price > 0 ? (((product.az_price -
                        (product.price * (1 - (props.filters.hasOwnProperty("priceCoupon") && (props.filters["priceCoupon"] / 100)))) -
                        product.selling_on_az_fees -
                        (!props.filters.isFBM && product.fba_cost) -
                        parseFloat(prep)) /
                        ((product.price * (1 - (props.filters.hasOwnProperty("priceCoupon") && (props.filters["priceCoupon"] / 100)))) + parseFloat(prep))) *
                        100
                      ).toFixed(2) : "OOS on AMZ"}
                      {product.az_price > 0 && "%"}
                    </td>
                    <td style={{ width: "15vw" }}>
                      <div
                        className="columns is-centered"
                        style={{ display: "flex" }}
                      >
                        <button
                          className={"button is-rounded" + (!localStorage.getItem("clickedActions") ? " hidebtnglow" : "")}
                          id="hideTt"
                          style={{
                            backgroundColor: "#8B3C7E",
                            color: "white",
                            fontWeight: "700",
                            border: "#8B3C7E",
                            fontSize: ".64vw",
                          }}
                          onClick={() => {
                            setHideModalProduct(product);
                          }}
                        >
                          HIDE FROM OTHERS
                        </button>
                        <FavoritesIcon oos={false} url={product.url} favorite={product.favorite} />
                      </div>
                      <div
                        className={"columns is-centered" + (!localStorage.getItem("clickedActions") ? " actions" : "")}
                        style={{ display: "flex", marginTop: "1px" }}
                        onClick={() => localStorage.setItem("clickedActions", true)}
                      >
                        <i class="fa fa-search graphicon tooltip" style={{
                          width: "2.25vw",
                          marginTop: "1px",
                          marginLeft: "3px",
                          height: "2.25vw",
                          borderRadius: "20px",
                          border: "2px solid gray",
                          padding: "0.35vw 0vw",
                          cursor: "pointer",
                          fontSize: "1.35vw",
                        }} aria-hidden="true"
                          onClick={() => setSearchModal(product)}
                          id="searchTt"
                        ><span
                          class="tooltiptext"
                          style={{
                            marginTop: "-25%",
                            fontSize: ".75vw",
                            zIndex: "2000",
                            padding: "3px 6px",
                            border: "1px solid black",
                            fontFamily: "sans-serif",
                          }}
                        >
                            Check in other suppliers
                          </span></i>
                        <i
                          className={`fa ${gatedStatus.hasOwnProperty(product.asin.split("/")[product.asin.split("/").length - 1]) && gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions ? (gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions.length === 0 ? "fa-unlock" : (gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions[0].reasons[0].reasonCode === "APPROVAL_REQUIRED" ? "fa-unlock-alt" : "fa-lock")) : "fa-unlock-alt"} graphicon tooltip`}
                          style={{
                            width: "2.25vw",
                            marginTop: "1px",
                            marginLeft: "3px",
                            height: "2.25vw",
                            borderRadius: "20px",
                            border: "2px solid gray",
                            padding: "0.3vw 0.2vw",
                            cursor: "pointer",
                            fontSize: "1.6vw",
                            color: gatedStatus.hasOwnProperty(product.asin.split("/")[product.asin.split("/").length - 1]) && gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions ? "white" : "rgb(195, 65, 2)",
                            backgroundColor: gatedStatus.hasOwnProperty(product.asin.split("/")[product.asin.split("/").length - 1]) && gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions ? (gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions.length === 0 ? "green" : (gatedStatus[product.asin.split("/")[product.asin.split("/").length - 1]].restrictions[0].reasons[0].reasonCode === "APPROVAL_REQUIRED" ? "orange" : "red")) : "white"
                          }}
                          id="gatedTt"
                          onClick={() => {
                            if (props.sellerId === "none" || props.sellerId === "") {
                              setLoginWAZ(true);
                            }
                            window
                              .open(
                                "https://sellercentral.amazon.com/hz/approvalrequest/restrictions/approve?asin=" +
                                product.asin.split("/")[
                                product.asin.split("/").length - 1
                                ],
                                "_blank"
                              )
                              .focus()
                          }}
                          aria-hidden="true"
                        >
                          <span
                            class="tooltiptext"
                            style={{
                              zIndex: "2000",
                              fontFamily: "sans-serif",
                              marginTop: "-25%",
                              fontSize: ".75vw",
                            }}
                          >
                            Check if Gated
                          </span>
                        </i>
                        <img
                          className="graphicon"
                          alt="keepa"
                          id="keepaTt"
                          onClick={() =>
                            window.open(
                              `https://keepa.com/#!product/1-${product.asin.split("/")[
                              product.asin.split("/").length - 1
                              ]
                              }}`,
                              "_blank"
                            )
                          }
                          src={"/assets/keepa.png"}
                          style={{
                            width: "2.25vw",
                            marginTop: "1px",
                            marginLeft: "3px",
                            height: "2.25vw",
                            borderRadius: "20px",
                            border: "2px solid gray",
                            padding: "3px",
                          }}
                        />
                        <i
                          className="fa fa-exclamation-triangle graphicon tooltip"
                          style={{
                            width: "2.25vw",
                            marginTop: "1px",
                            marginLeft: "3px",
                            height: "2.25vw",
                            borderRadius: "20px",
                            border: "2px solid gray",
                            padding: "0.4vw 0.2vw",
                            cursor: "pointer",
                            fontSize: "1.3vw",
                            color: "darkred",
                          }}
                          id="reportTt"
                          onClick={() => {
                            if (
                              window.confirm(
                                "Report a mismatch?\nNote: Users who find 50 mismatches/out of stock will receive a free subscription!\n\nThe report will be sent to our team for verification. After confirming the mismatch, it will be counted."
                              )
                            ) {
                              reportMismatch(
                                product.asin.split("/")[
                                product.asin.split("/").length - 1
                                ], product.source_name
                              );
                            }
                          }}
                          aria-hidden="true"
                        >
                          {" "}
                          <span
                            class="tooltiptext"
                            style={{
                              zIndex: "2000",
                              fontFamily: "sans-serif",
                              marginTop: "-25%",
                              fontSize: ".75vw",
                            }}
                          >
                            Report a mismatch / out of stock product
                          </span>
                        </i>
                      </div>
                    </td>
                  </tr>
                  {props.showAdv && <tr>
                    <td colSpan={"12"} style={{ borderColor: "#E3D0DF" }}>
                      <AdvancedData
                        key={product.asin + "-" + props.page}
                        isItTheProduct={advancedDataProduct === product}
                        advancedDataProduct={advancedDataProduct}
                        setProduct={() => setAdvancedDataProduct(product)}
                        closeAdvancedData={() => setAdvancedDataProduct(null)}
                        isMobile={props.isMobile}
                        setShowAdv={props?.setShowAdv ?? null} />
                    </td></tr>}
                </>
                );
              }
            })}
            {props.isProductsLoading === true && props.data.slice((props.page - 1) * 10, props.page * 10).length < 10 && <tr><td colSpan={12}><i
              className="fa fa-spinner fa-spin"
              aria-hidden="true"
              style={{ fontSize: "250%", textAlign: 'center', width: "100%" }}
            ></i><br />The scan may take up to 3 minutes.</td></tr>}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="12" style={{ border: "none" }}>
                <button className="button" disabled={props.page === 1} onClick={() => {
                  props.setPage(p => p - 1); window.scrollTo({
                    top: 0,
                    behavior: 'smooth' // for smooth scrolling
                  });
                }} style={{ float: "left", backgroundColor: "#8B3C7E", color: 'white' }}>Previous Page</button>
                <span>Page {props.page}</span>
                <button className="button" disabled={((props.page) * 10) > props.data.length} onClick={() => {
                  props.setPage(p => p + 1); window.scrollTo({
                    top: 0,
                    behavior: 'smooth' // for smooth scrolling
                  });
                }} style={{ float: "right", backgroundColor: "#8B3C7E", color: 'white' }}>Next Page</button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      {loginWAZ && localStorage.getItem("no_azlog_popup") !== "1" && (
        <div
          id="modal-js-example"
          className={
            "modal" + (loginWAZ !== null ? " is-active" : "")
          }
        >
          <div className="modal-background"></div>
          <div
            className="modal-content"
            style={{ width: props.isMobile ? "90vw" : "38vw" }}
          >
            <div className="box" style={{ textAlign: "center", fontWeight: "600" }}>
              <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
                Log in to your Amazon account to automate the eligibility check!
              </p>
              <br />
              <i
                className="fa fa-unlock graphicon tooltip"
                style={{
                  width: "2.25vw",
                  height: "2.25vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.3vw 0.2vw",
                  fontSize: "1.6vw",
                  color: "white",
                  backgroundColor: "green"
                }} />&emsp;GREEN - You CAN sell this item!
              <br /><br />
              <i
                className="fa fa-lock graphicon tooltip"
                style={{
                  width: "2.25vw",
                  height: "2.25vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.3vw 0.2vw",
                  fontSize: "1.6vw",
                  color: "white",
                  backgroundColor: "red"
                }} />&emsp;RED - You CANNOT sell this item!
              <br /><br />
              <i
                className="fa fa-unlock-alt graphicon tooltip"
                style={{
                  width: "2.25vw",
                  height: "2.25vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.3vw 0.2vw",
                  fontSize: "1.6vw",
                  color: "white",
                  backgroundColor: "orange"
                }} />&emsp;ORANGE - You need to APPLY to sell this item!
              <br /><br /><br />
              <i class="fa fa-arrow-right" aria-hidden="true" style={{ fontSize: "25px", marginRight: "10px" }}></i>
              {user && user.email && <img src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png" onClick={() => window.open('https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.5e50b4d6-1a6f-46fa-a523-7dc2bd0266a8&version=beta&state=' + user.email)} style={{ resizeMode: 'center', cursor: "pointer", boxShadow: "0px 0px 5px 0px gray", borderRadius: "5px", marginBottom: "-8px" }} />}
              <i class="fa fa-arrow-left" aria-hidden="true" style={{ fontSize: "25px", marginLeft: "10px" }}></i>
              <br /><br /><br />
              <div className="columns is-mobile is-centered" style={{ marginBottom: "1%", marginTop: '1%' }}>
                <button
                  className="button is-rounded is-danger is-outlined"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setLoginWAZ(false);
                    localStorage.setItem("no_azlog_popup", "1")
                  }}
                >
                  Do not show this message again
                </button>&emsp;
                <button
                  className="button is-rounded is-danger"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setLoginWAZ(false);
                  }}
                >
                  Close for Now
                </button>
              </div>
            </div>
          </div>
        </div>)}
      {hideModalProduct !== "" && (
        <ModalForHide
          isMobile={props.isMobile}
          setHideModalProduct={setHideModalProduct}
          hideModalProduct={hideModalProduct}
          hideCredits={props.hideCredits}
        />
      )}
      {linkUrl !== "" && (
        <NotDirectLinkPopup
          isMobile={props.isMobile}
          setLinkUrl={setLinkUrl}
          linkUrl={linkUrl}
        />
      )}
      {showMessageObj !== null && (
        <MessageBeforeLinkPopup
          showMessageObj={showMessageObj}
          setShowMessageObj={setShowMessageObj}
        />
      )}
      {modalForVariations &&
        <ModalForVariationsTable
          variations={-999} // Custom flag to fetch variations
          currAsin={modalForVariationsAsin}
          modalForVariations={modalForVariations}
          setModalForVariations={setModalForVariations}
          isMobile={props.isMobile} />
      }
      {searchModal !== null && (
        <div
          id="modal-js-example"
          className={
            "modal" + (searchModal !== null ? " is-active" : "")
          }
        >
          <div className="modal-background" onClick={() => {
            setSearchModal(null);
          }}></div>
          <div
            className="modal-content"
            style={{ width: props.isMobile ? "90vw" : "38vw" }}
          >
            <div className="box" style={{ textAlign: "center" }}>
              <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
                Check in other suppliers
              </p>
              <p>
                A specific ASIN can be found from multiple suppliers in Nepeto. By clicking the Nepeto button, you can compare all prices from different suppliers.
              </p>
              <br />
              <img
                className="graphicon"
                alt="ebay"
                onClick={() =>
                  window.open(searchModal.upc !== null ?
                    `https://www.ebay.com/sch/i.html?&_nkw=${searchModal.upc}&_odkw=${searchModal.upc}` :
                    `https://www.ebay.com/sch/i.html?&_nkw=${searchModal.title.split(" | At")[0].slice(0, 40)}&_odkw=${searchModal.title.split(" | At")[0].slice(0, 40)}`,
                    "_blank"
                  )
                }
                src={"/assets/ebay.png"}
                style={{
                  width: "5vw",
                  marginTop: "10px",
                  height: "3vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.7vw",
                  objectFit: "contain",
                  cursor: "pointer"
                }}
              />&emsp;&emsp;&emsp;
              <img
                className="graphicon"
                alt="ebay"
                onClick={() =>
                  window.open("https://nepeto.com/retail?asin=" + searchModal.asin.split("/")[searchModal.asin.split("/").length - 1
                  ])}
                src={logo}
                style={{
                  width: "5vw",
                  marginTop: "10px",
                  height: "3vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "3px",
                  objectFit: "contain",
                  cursor: "pointer"
                }}
              />&emsp;&emsp;&emsp;
              <img
                className="graphicon"
                alt="ebay"
                onClick={() =>
                  window.open("https://www.google.com/search?q=" + searchModal.title.split(" | At")[0])}
                src={"https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/250px-Google_2015_logo.svg.png"}
                style={{
                  width: "5vw",
                  marginTop: "10px",
                  height: "3vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.5vw",
                  objectFit: "contain",
                  cursor: "pointer"
                }}
              />
              <br /><br /><br />
              <div className="columns is-mobile is-centered">
                <button
                  className="button is-rounded is-danger"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setSearchModal(null);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>)}
      {saveMoney !== null && (
        <div
          id="modal-js-example"
          className={
            "modal" + (saveMoney !== null ? " is-active" : "")
          }
        >
          <div className="modal-background" onClick={() => {
            setSaveMoney(null);
            setSaveMoneyRates({});
          }}></div>
          <div
            className="modal-content"
            style={{ width: props.isMobile ? "90vw" : "38vw" }}
          >
            <div className="box" style={{ textAlign: "center" }}>
              <p style={{ fontSize: props.isMobile ? "2.5vh" : "1.1vw" }}>
                Save Money on <strong>{saveMoney.charAt(0).toUpperCase() + saveMoney.slice(1)}</strong>!
              </p>
              <p>
                Save money using cashback or a gift card from one of these websites!
                <br />
                <span style={{ fontSize: "95%" }}>After you find your desired discount, please apply it in More->Coupon filter to include it in the calculations and find more profitable products.</span>
                <br /><span style={{ fontSize: "85%" }}>*Note that it will search again, so save the ASIN before applying and search for it afterward.</span>
                <br />
              </p>
              <br />
              <img
                className="graphicon"
                alt="gcwiki"
                onClick={() =>
                  window.open("https://www.giftcardwiki.com/gift-cards/" + (saveMoney.charAt(0).toUpperCase() + saveMoney.slice(1)), "_blank")
                }
                src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOV3EZSZpGuhYiChjIYalZ5ma5QYQlG22RTAqKUuQn5A&s"}
                style={{
                  width: "5vw",
                  marginTop: "10px",
                  height: "3vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.7vw",
                  objectFit: "contain",
                  cursor: "pointer"
                }}
              />
              {!saveMoneyRates.hasOwnProperty("cardbear") ?
                <span style={{ marginLeft: "2vw", position: "absolute", marginTop: "3.6vw", fontSize: "0.75vw", textAlign: "center", width: "5vw" }}>Loading...</span> :
                <span style={{ marginLeft: "2vw", position: "absolute", marginTop: "3.6vw", fontSize: "0.7vw", color: saveMoneyRates['cardbear'] === -1 ? "red" : "green", textAlign: "center", width: "5vw" }}>{saveMoneyRates['cardbear'] === -1 ? "" : "Earn up to " + saveMoneyRates['cardbear'].toFixed(1) + "%"}</span>}
              <img
                className="graphicon"
                alt="ebay"
                onClick={() =>
                  window.open("https://www.cardbear.com/", "_blank")
                }
                src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLVFyxOrlEiIO-lo5ty1xtrxX2mBoNR3rt7Q&s"}
                style={{
                  marginLeft: "2vw",
                  width: "5vw",
                  marginTop: "10px",
                  height: "3vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.1vw",
                  objectFit: "contain",
                  cursor: "pointer"
                }}
              />
              {!saveMoneyRates.hasOwnProperty("arbcard") ?
                <span style={{ marginLeft: "2vw", position: "absolute", marginTop: "3.6vw", fontSize: "0.75vw", textAlign: "center", width: "5vw" }}>Loading...</span> :
                <span style={{ marginLeft: "2vw", position: "absolute", marginTop: "3.6vw", fontSize: "0.7vw", color: saveMoneyRates['arbcard'] === -1 ? "red" : "green", textAlign: "center", width: "5vw" }}>{saveMoneyRates['arbcard'] === -1 ? "" : "Earn up to " + saveMoneyRates['arbcard'].toFixed(1) + "%"}</span>}
              <img
                className="graphicon"
                alt="ebay"
                onClick={() =>
                  window.open("https://arbitragecard.com/giftcards/?utm_source=nepeto", "_blank")
                }
                src={"https://arbitragecard.com/wp-content/uploads/2022/07/LOGO.svg"}
                style={{
                  width: "5vw",
                  marginLeft: "2vw",
                  marginTop: "10px",
                  height: "3vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.7vw",
                  objectFit: "contain",
                  cursor: "pointer",
                  backgroundColor: "black",
                }}
              />
              <img
                className="graphicon"
                alt="ebay"
                onClick={() =>
                  window.open(`https://www.rakuten.com/r/IG01PR?eeid=28187`, "_blank")
                }
                src={"https://static.rakuten.com/static/svg/rakuten/rak-logo-brand-v1.svg"}
                style={{
                  width: "5vw",
                  marginLeft: "2vw",
                  marginTop: "10px",
                  height: "3vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "3px",
                  objectFit: "contain",
                  cursor: "pointer"
                }}
              />
              <img
                className="graphicon"
                alt="ebay"
                onClick={() =>
                  window.open(`https://www.befrugal.com/rs/KNCETGG/`, "_blank")
                }
                src={"https://icdn.bfrl.us/headerfooterimages/logo.png"}
                style={{
                  width: "5vw",
                  marginLeft: "2vw",
                  marginTop: "10px",
                  height: "3vw",
                  borderRadius: "20px",
                  border: "2px solid gray",
                  padding: "0.5vw",
                  objectFit: "contain",
                  cursor: "pointer"
                }}
              />
              <br /><br /><br /><br />
              <div className="columns is-mobile is-centered">
                <button
                  className="button is-rounded is-danger"
                  style={{ fontSize: props.isMobile ? "1.9vh" : ".95vw" }}
                  onClick={() => {
                    setSaveMoney(null);
                    setSaveMoneyRates({});
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>)}
    </>
  );
}
