import React, { useEffect, useState } from "react";
import "./Pricing.css";
import PriceCard from "./components/PriceCard";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import CancelSubModal from "../../components/CancelSubModal";

function Pricing(props) {
  const { isAuthenticated, isLoading } = useAuth0();

  const prices = [
    {
      name: "Starter",
      price_monthly: 34,
      price_annually: 29,
      img: "/assets/advancedFlower.png",
      checkList: [
        "Profitable products from Retail Suppliers (Walmart, Target, etc.)",
        "2 Mobile App Installs",
        "2 Chrome Extension Installs",
        "Discover products you're already APPROVED to sell!",
        "Storefront Scanner: 3/day",
        "7 Days Free Trial",
      ],
      planLevel: 1,
      prd_id_monthly: "105675",
      prd_id_monthly_no_trial: "105676",
      prd_id_annually: "105673",
      prd_id_annually_no_trial: "105674",
    },
    {
      name: "Advanced",
      price_monthly: 78,
      price_annually: 62,
      img: "/assets/advancedFlower.png",
      checkList: [
        "Profitable products from Retail Suppliers (Walmart, Target, etc.)",
        "Profitable products from Distributors/Wholesale Suppliers",
        "Profitable products that are currently Out-of-Stock on Amazon",
        "Amazon Flips (A2A)",
        "Advanced Product Data",
        "Storefront Scanner: 6/day",
        "14 Days Free Trial",
      ],
      planLevel: 2,
      plan_id_monthly: "P-8FJ44572AR517302MM2MZQ2Y",
      plan_id_monthly_no_trial: "P-2PN15893PK525705AM2HOMEI",
      plan_id_annually: "P-53X247570J723262AM2MZP4A",
      plan_id_annually_no_trial: "P-43A14616AM7398420M2HOMVQ",
      prd_id_monthly: "101488",
      prd_id_monthly_no_trial: "101489",
      prd_id_annually: "101494",
      prd_id_annually_no_trial: "101495",
    },
    {
      name: "Pro",
      price_monthly: 98,
      price_annually: 78,
      img: "/assets/proFlower.png",
      checkList: [
        "Everything from the previous plan",
        "Amazon to Walmart (Walmart Sellers)",
        "Pro Data",
        "Pro Features: Filter By Profit, and Sort By 'Found Time'",
        "Export deals to CSV",
        "Storefront Scanner: 15/day",
        "14 Days Free Trial",
      ],
      planLevel: 3,
      plan_id_monthly: "P-6KY85952CP610184DM2MZO3I",
      plan_id_monthly_no_trial: "P-2M88605087687645YM2HORDI",
      plan_id_annually: "P-23M620651E1919059M2MZN4I",
      plan_id_annually_no_trial: "P-1L97866913221874YM2HOTRY",
      prd_id_monthly: "101490",
      prd_id_monthly_no_trial: "101487",
      prd_id_annually: "101497",
      prd_id_annually_no_trial: "101496",
    },
    {
      name: "Master",
      price_monthly: 99,
      price_annually: 83,
      isEnterprise: true,
      img: "/assets/enterpriseflower.png",
      checkList: [
        "Everything from the previous plans",
        "CSV-Scanner - Find products from given CSV",
        "Nepeto Master Search!!!",
        "Nepeto LIVE Search!!!",
        "Storefront Scanner: 30/day",
        "Nepeto RA Scanning App & Profit Calculator Chrome Extension",
        "👑 Nepeto Mastermind 👑"
      ],
      planLevel: -1,
      plan_id_monthly: "P-5C244369VX386000TM2LN65Q",
      plan_id_monthly_no_trial: "P-4WJ56364B2560852GM4DRYIA",
      plan_id_annually: "P-2GR357408U5110239M2LN7OQ",
      plan_id_annually_no_trial: "P-0JJ07786T67995607M4DRZHA",
      prd_id_monthly: "101486",
      prd_id_monthly_no_trial: "101485",
      prd_id_annually: "101498",
      prd_id_annually_no_trial: "101499",
    },
  ];
  const [isMonthly, setIsMonthly] = useState(false);
  const [activePlanLevel, setActivePlanLevel] = useState(undefined);
  const [cancelSubModal, setCancelSubModal] = useState(false);

  useEffect(() => {
    const getActivePlan = async () => {
      if (props.activePlanLevel === "Starter Plan") {
        setActivePlanLevel(1)
      } else if (props.activePlanLevel === "Advanced Plan") {
        setActivePlanLevel(2)
      } else if (props.activePlanLevel === "Pro Plan") {
        setActivePlanLevel(3)
      } else if (props.activePlanLevel === "Master Plan") {
        setActivePlanLevel(4)
      } else if (props.activePlanLevel !== "") {
        setActivePlanLevel(parseInt(props.activePlanLevel))
      }
    };
    if (!isLoading && isAuthenticated) {
      getActivePlan();
    }
  }, [props.activePlanLevel, isLoading, isAuthenticated])

  return (
    <div className="container">
      <Helmet>
        <title>Nepeto - Pricing</title>
        <meta
          name="description"
          content="Explore our pricing options and choose the plan that best suits your needs. Discover transparent and competitive pricing for our products and services. Find the perfect pricing plan for your requirements."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, pricing, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <div class="columns center">
        <span
          style={{
            position: "absolute",
            textAlign: "center",
            top: "3vh",
            fontSize: "200%",
            color: "rgb(140, 62, 124)",
          }}
        >
          <p onClick={() => window.open("https://calendly.com/igprojects/nepeto-advanced-tutorial-call")} style={{ cursor: "pointer", textDecoration: "underline", fontSize: props.isMobile ? "50%" : "70%", color: "slateblue" }}>Set a <strong style={{ color: "slateblue" }}>FREE</strong> call with an Amazon expert for a tutorial on secret sourcing methods using Nepeto</p>
        </span>
        <div class="column center" style={{ marginTop: props.isMobile ? "10vh" : "6vh", marginLeft: "5%", marginBottom: "1vh" }}>
          <span
            style={{
              fontSize: "1.5rem",
              color: "rgb(140, 62, 124)",
              fontWeight: isMonthly && "bold",
              padding: "1rem",
              cursor: "pointer"
            }}
            onClick={() => setIsMonthly(!isMonthly)}
          >
            Monthly
          </span>{" "}
          <label class="switch">
            <input type="checkbox" onClick={() => setIsMonthly(!isMonthly)} checked={!isMonthly} />
            <span class="slider round"></span>
          </label>
          <span
            style={{
              fontSize: "1.5rem",
              color: "rgb(140, 62, 124)",
              fontWeight: !isMonthly && "bold",
              padding: "1rem",
              width: "30%",
              whiteSpace: "nowrap",
              cursor: "pointer"
            }}
            onClick={() => setIsMonthly(!isMonthly)}
          >
            Annually {props.isMobile && <br />}<span style={{ backgroundColor: "lightgreen", padding: "0% 5%", borderRadius: "30px", boxShadow: "0px 0px 5px 0px green" }}><span style={{ fontSize: "80%", color: "red", textDecoration: "line-through", display: props.isMobile ? "none" : "inline" }}>20%+</span> <span style={{
              textShadow: '0 1px 5px rgba(250, 250, 200, 1)',
              fontSize: "110%"
            }}>44%+</span> OFF!</span>
          </span>{" "}
        </div>
      </div>
      {props.activePlanLevel.includes("Plan") ? <div style={{ marginTop: "-3vh", textAlign: "center", fontSize: "125%", border: "1px solid green", padding: ".5%" }}>
        Current Plan: {props.activePlanLevel}&nbsp; | &nbsp;{props.subscriptionActive === undefined ? "Loading..." : (props.subscriptionActive ? ("Next billing date: " + (props.nextBillingTime ? new Date(props.nextBillingTime).toLocaleDateString() : props.nextBillingTime)) : ("Cancelled, Access remains until: " + (props.nextBillingTime ? new Date(props.nextBillingTime).toLocaleDateString() : props.nextBillingTime)))}{props.subscriptionActive !== undefined && props.subscriptionActive && <>&nbsp;<button className="button" style={{ padding: "2px", height: "auto" }} onClick={() => setCancelSubModal(true)}>Cancel Subscription</button></>}
      </div> : <span className="cloud-span" style={{ marginTop: "-3vh", fontSize: "125%", }}>
      Buy One, Get One! Your VA can use Nepeto too!
      </span>}

      <br /><br />
      <div className="columns">
        {prices.map((item) => {
          return <PriceCard plan={item} plan_id={item.plan_id} isMonthly={isMonthly} activePlanLevel={activePlanLevel} isMobile={props.isMobile}></PriceCard>;
        })}
      </div>

      <br/><br/>
      <img src="/assets/PIONEERS.png" onClick={() => window.open("https://www.getmarter.com/pioneers-dashboard")} style={{width: "30%", borderRadius: "20px", boxShadow: "0px 0px 5px 0px green", cursor: "pointer", margin: "auto", display: "block"}}/>
      <br/><br/>

      <div class="columns">
        <div class="column">
          <h2
            style={{
              fontSize: "2vh",
              color: "#000000",
              textAlign: "center",
              marginBottom: "4vh",
            }}
          >
            Bring your friends and earn <strong>30%</strong> monthly! <a href="/affiliate" alt="affiliate-program" target="_blank" rel="noreferrer">Click here to join our affiliate program today!</a>
            <br />
            Want to learn more? <a href="/how-to-use-nepeto" alt="how-to-use-nepeto" target="_blank" rel="noreferrer">Click here to see how to use Nepeto</a>
            <br />
            Subscriptions can be canceled at any time. Click&nbsp;
            <span style={{ color: "slateblue", cursor: "pointer" }} onClick={() => { setCancelSubModal(true) }}>
              here
            </span>
            &nbsp;to cancel.
            <br />
            If you have any questions, please don't hesitate to{" "}
            <Link to="/contact">contact us</Link> or check our{" "}
            <Link to="/faq">FAQ</Link>.
            <br />
            By starting a subscription, you acknowledge and accept our{" "}
            <a href="/termsandpolicies.pdf">Terms and Conditions</a>.
          </h2>
        </div>
      </div>
      {cancelSubModal && <CancelSubModal setCancelSubModal={setCancelSubModal} />}
    </div>
  );
}
export default Pricing;
