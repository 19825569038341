import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import StepIndicator from "./StepIndicator";
import FilterBar from "./FilterBar";
import ProductsTable from "./ProductsTable";
import SupplierModal from "./SupplierModal";
import { useAuth0 } from "@auth0/auth0-react";

function LiveSearch(props) {
  const {
    isAuthenticated,
    getAccessTokenSilently,
  } = useAuth0();
  const [input, setInput] = useState("");
  const [tableData, setTableData] = useState([]);
  const [productsScanned, setProductsScanned] = useState([]);
  const [isSupplierModalActive, setIsSupplieModalActive] = useState(false);
  const underMain = []

  const HOST = "https://server.nepeto.com"

  // Products Table
  const [products, setProducts] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({});
  const [sortASC, setSortASC] = useState(false);
  const SUPPLIERS_LIST = ['1000bulbs', 'acehardware', 'academy', 'aerosoles', 'annscottage', 'barnesandnoble', 'bestbuy', 'boscovs', 'bhphotovideo', 'dillards', 'everythingkitchens', 'nike', 'peltzshoes',
    'farmandfleet', 'fatbraintoys', 'fivebelow', 'iherb', 'gamestop', 'gnc', 'homedepot', 'kroger', 'lowes', 'petsmart', 'webstaurantstore', 'fleetfarm', 'jcpenney', 'rei', 'zappos', 'yankeecandle', 'cvs',
    'target', 'underarmour', 'walmart', 'dickssportinggoods', 'kohls', 'samsclub', 'zoro', 'walgreens', 'chewy', 'frontiercoop', 'officedepot', 'vitacost', 'joann', "e-cosmetorium",
    "rinovelty", "altomusic", "bbtoystore", "beautylish", "bedbathandbeyond", "buydig", "dermstore", "ecosmetorium", "footsmart", "hartmannvariety", "globalgolf",
    "hearthsong", "herbspro", "jefferspet", "keepsakequilting", "microcenter", "officesupply", "parts-express", "pcrichard", "rarebeauty", "riteaid", "roadrunnersports", "ruralking",
    "bangalla", "belk", "bloomingdales", "caretobeauty", "fye", "healthypets", "luggagepros", "mytoolstore", "opentip", "packershoes", "pinkcherry", "pureformulas", "vitaminlife"].toSorted() // macysbathandbodyworksdisney, 
  const CATEGORY_BSR_COUNT = {
    'Kindle Store': 6756566, 'Video Shorts': 127580, 'Apps & Games': 936100, 'Baby Products': 4172515,
    'Digital Music': 68941656, 'Alexa Skills': 112529, 'Toys & Games': 9204843,
    'Patio, Lawn & Garden': 22608266, 'Books': 102997339, 'Arts, Crafts & Sewing': 16296162,
    'Software': 177161, 'Sports & Outdoors': 41416624, 'Gift Cards': 28409, 'Video Games': 1204647,
    'Handmade Products': 3644844, 'Clothing, Shoes & Jewelry': 275101033, 'Office Products': 12266328,
    'Grocery & Gourmet Food': 3869382, 'Tools & Home Improvement': 37997159, 'Movies & TV': 7740340,
    'Musical Instruments': 2893522, 'Collectibles & Fine Art': 8802597, 'Appliances': 1565048,
    'Pet Supplies': 8652837, 'Cell Phones & Accessories': 26169713, 'Baby': 4172515,
    'Industrial & Scientific': 20388882, 'Everything Else': 8317014, 'CDs & Vinyl': 8366789,
    'Beauty & Personal Care': 14890600, 'Home & Kitchen': 161989558, 'Electronics': 25856893,
    'Automotive': 46255283, 'Magazine Subscriptions': 42578, 'Health & Household': 11049107,
    'Audible Books & Originals': 743380, 'Kitchen & Dining': 37818304, "Amazon Devices": 1894,
    "Computers & Accessories": 14064999, "Camera & Photo Products": 4021127,
    "Collectible Coins": 210648, "Camera & Photo": 4021127
  }

  const isStarted = tableData.some(
    row =>
      row.isWorking === true ||
      row.shouldStop === false
  );

  const isLoading = tableData.some(
    row =>
      row.isWorking === true &&
      row.shouldStop === false
  ) && products.length > 0 || productsScanned.length > 0;

  function removeDuplicates(list) {
    var uniqueObjects = {};
    var result = [];

    list.forEach(function (obj) {
      var key = obj.asin + obj.source;

      if (!uniqueObjects[key]) {
        uniqueObjects[key] = true;
        result.push(obj);
      }
    });

    return result;
  }

  const BATCH_SIZE = 10;

  const checkSupplier = (urlString) => {
    try {
      // Use the URL constructor to check if it's a valid URL
      const url = new URL(urlString);

      // Remove 'www.' if present and get the domain
      const domain = url.hostname.replace("www.", "");

      // Extract the base domain (e.g., 'amazon' from 'amazon.com')
      const supplier = domain.split('.')[0];

      // Check if the supplier exists in the SUPPLIERS_LIST
      const hasSupplier = SUPPLIERS_LIST.includes(supplier);

      // Return the result
      return { supplier: supplier, exists: hasSupplier };
    } catch (err) {
      console.error('Invalid URL:', err);
      return { supplier: '', exists: false };
    }
  };

  const checkURL = (url) => {
    const supplierData = checkSupplier(url);

    if (underMain.includes(supplierData.supplier)) {
      alert(`${supplierData.supplier} is currently under maintenance.`)
      return false
    } 
    if (supplierData.exists) {
      return true; // Supplier is in the list
    }

    // Handle invalid supplier or URL cases
    if (supplierData.supplier === "") {
      alert('Please enter a valid URL\nExample of valid URL: https://www.domain.com/path?query=value');
      setInput('');
    } else {
      alert(`${supplierData.supplier} is not a supported supplier. Please check our list of supported suppliers or submit a request to add a new supplier below.`)
      setIsSupplieModalActive(true);
    }

    return false;
  };

  const handleSearch = () => {
    if (checkURL(input)) {
      const newUrl = input.replaceAll(" ", "").replaceAll("\t", "");
      setInput("");

      // Add initial row to the table
      const newRow = {
        url: newUrl,
        source: '',
        productsURLs: [],
        totalURLs: 0,
        pageNumber: 1,
        step: 1,
        productsScanned: 0,
        isLoading: true,
        shouldStop: false,
        isWorking: false,
        nextPageUrl: newUrl  // Initially, the next page URL is the input URL
      };
      setTableData((prevData) => [...prevData, newRow]);
    }
  };

  const stopFetching = (url) => {
    setTableData((prevData) =>
      prevData.map((row) =>
        row.url === url ? { ...row, shouldStop: true, isLoading: false, isWorking: false, step: 3 } : row
      )
    );
  };

  const updateProductsScanned = useCallback((data) => {
    setProductsScanned((prevData) => [...prevData, ...data]);
  }, []);

  const updateTableRow = useCallback((url, updates) => {
    setTableData((prevData) =>
      prevData.map((row) =>
        row.url === url ? { ...row, ...updates } : row
      )
    );
  }, []);

  const processStep = useCallback(async (row) => {
    if (row.shouldStop) return;

    try {
      updateTableRow(row.url, { isWorking: true });

      switch (row.step) {
        case 1: // Get product URLs
          const accessToken = await getAccessTokenSilently();
          const response = await fetch(`${HOST}/live_search_get_products_urls/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
            mode: "cors",
            body: JSON.stringify({ url: row.nextPageUrl, source: row.source }),
          });
          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
          const data = await response.json();
          if (!data.ok) alert(`No Source ${data.data}`);
          if (data.data.urls.length === 0 || (row.productsURLs.length === data.data.urls.length && row.productsURLs.sort().every((value, index) => value === data.data.urls.sort()[index]))) updateTableRow(row.url, { isLoading: false })
          updateTableRow(row.url, {
            productsURLs: data.data.urls,
            totalURLs: row.totalURLs + data.data.urls.length,
            step: 2,
            nextPageUrl: data.data.next,
            currentBatchIndex: 0,
            source: data.data.source
          });
          break;

        case 2: // Fetch single product data in batches
          const startIndex = row.currentBatchIndex * BATCH_SIZE;
          const endIndex = startIndex + BATCH_SIZE;
          const batch = row.productsURLs.slice(startIndex, endIndex);

          if (batch.length > 0) {
            const accessToken = await getAccessTokenSilently();
            const productResponse = await fetch(`${HOST}/live_search_fetch_single_product_data/`, {
              method: 'POST',
              mode: "cors",
              headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
              body: JSON.stringify({ urls: batch, source: row.source }),
            });
            if (!productResponse.ok) throw new Error(`HTTP error! status: ${productResponse.status}`);
            const productData = await productResponse.json();
            if (!productData.ok) alert(`No Source ${productData.data}`);
            updateProductsScanned(productData.data);
            updateTableRow(row.url, {
              productsScanned: row.productsScanned + productData.data.length,
              currentBatchIndex: row.currentBatchIndex + 1,
            });
          } else {
            updateTableRow(row.url, { step: 3, });
          }
          break;

        case 3: // Process Data (wait and potentially restart)
          await new Promise((resolve) => setTimeout(resolve, 10000));
          if (row.nextPageUrl) {
            updateTableRow(row.url, { step: 1, pageNumber: row.pageNumber + 1 });
          } else {
            updateTableRow(row.url, { isLoading: false });
          }
          break;
      }

    } catch (error) {
      console.error("Error processing data:", error);
      updateTableRow(row.url, { isLoading: false });
    } finally {
      updateTableRow(row.url, { isWorking: false });
    }
  }, [updateTableRow, updateProductsScanned]);

  const sendToKeepa = async (items) => {
    const batch = items.filter(item => item && item.upc).map(item => {
      return {
        "Barcode": item["upc"],
        "Price": item["price"] ? item["price"] : 0,
        "Name": item["title"],
        "Image": item["img"],
        "Source": item["source_name"],
        "URL": item["url"].replace('X'.repeat(item['source_name'].length), item['source_name'])
      }
    });
    const accessToken = await getAccessTokenSilently();
    const response = await fetch(`${HOST}/live_search_get_data/`, {
      method: 'POST',
      mode: "cors",
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
      body: JSON.stringify(batch),
    });
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const data = await response.json();

    setProducts((prev) => [...prev, ...data]);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      tableData.forEach((row) => {
        if (row.isLoading && !row.shouldStop && !row.isWorking) {
          processStep(row);
        }
      });
    }, 500);

    return () => clearInterval(interval);
  }, [tableData, processStep]);

  useEffect(() => {
    const interval = setInterval(() => {
      setProductsScanned((prevProducts) => {
        if (prevProducts.length === 0) return prevProducts;

        // Pop BATCH_SIZE items
        const itemsToPop = prevProducts.slice(0, BATCH_SIZE);
        const remainingItems = prevProducts.slice(BATCH_SIZE);

        sendToKeepa(itemsToPop);

        return remainingItems; // Update state with the remaining items
      });
    }, 10000); // 5000

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    function calculateROI(item, settings) {
      let logisticsCost = parseFloat(localStorage.getItem("costPrep") || 0);
      let price = item.price * (1 - ((settings.priceCoupon || 0) / 100));
      let azPrice = item.az_price;
      let fbaCost = settings.isFBM ? 0 : item.fba_fee;
      let sellingOnAzFees = item.ref_fee_perc * item.az_price;

      return (azPrice - price - fbaCost - logisticsCost - sellingOnAzFees) / (price + logisticsCost);
    }

    function filterData(data, filters) {
      return data.filter(item => {
        // noMultiPacks
        if (parseInt(filters.noMultiPacks || 0) !== 0 && item.title && (item.title.toLowerCase().includes("pack") || item.title.toLowerCase().includes("count") || (item.pack && item.pack > 1))) {
          return false;
        }

        // noVariations
        if (parseInt(filters.noVariations || 0) !== 0 && item.variations !== "null") {
          return false;
        }

        // amazonNotSeller
        if (parseInt(filters.amazonNotSeller || 0) !== 0) {
          if (item.az_sells_it) {
            return false;
          }
        }

        // top_bsr
        if (filters.top_bsr && (filters.top_bsr[0] !== -1 || filters.top_bsr[1] !== -1)) {
          let categoryCount = CATEGORY_BSR_COUNT[item.category] || 10000000;
          let salesRankPercentage = (item.sales_rank / categoryCount) * 100;

          if (filters.top_bsr[0] !== -1 && salesRankPercentage < filters.top_bsr[0]) {
            return false;
          }

          if (filters.top_bsr[1] !== -1 && salesRankPercentage > filters.top_bsr[1]) {
            return false;
          }
        }

        // profit
        if (filters.profit && (filters.profit[0] !== -1 || filters.profit[1] !== -1)) {
          let profitValue = item.az_price - (item.price * (1 - ((filters.priceCoupon || 0) / 100))) - (filters.isFBM ? 0 : item.fba_fee) - (item.ref_fee_perc * item.az_price) - parseFloat(localStorage.getItem("costPrep") || 0);

          if (filters.profit[0] !== -1 && profitValue < filters.profit[0]) {
            return false;
          }

          if (filters.profit[1] !== -1 && profitValue > filters.profit[1]) {
            return false;
          }
        }

        // search
        if (filters.search && filters.search !== "") {
          let searchLower = filters.search.toLowerCase();
          if (!item.title.toLowerCase().includes(searchLower) && !item.asin.includes(filters.search)) {
            return false;
          }
        }

        // category
        if (filters.category && filters.category !== "Category" && !filters.category.includes(item.category)) {
          return false;
        }

        // qty, moq, bsr
        for (let filterAttr of ["qty", "moq", "bsr"]) {
          if (filters[filterAttr] && (filters[filterAttr][0] !== -1 || filters[filterAttr][1] !== -1)) {
            let value = item[filterAttr === "bsr" ? "sales_rank" : filterAttr];

            if (filters[filterAttr][0] !== -1 && value < filters[filterAttr][0]) {
              return false;
            }

            if (filters[filterAttr][1] !== -1 && value > filters[filterAttr][1]) {
              return false;
            }
          }
        }

        // price
        if (filters.price && (filters.price[0] !== -1 || filters.price[1] !== -1)) {
          let price = item.price * (1 - ((filters.priceCoupon || 0) / 100));

          if (filters.price[0] !== -1 && price < filters.price[0]) {
            return false;
          }

          if (filters.price[1] !== -1 && price > filters.price[1]) {
            return false;
          }
        }

        // roi
        if (filters.roi && (filters.roi[0] !== -1 || filters.roi[1] !== -1)) {
          let roi = calculateROI(item, filters);

          if (filters.roi[0] !== -1 && roi < (filters.roi[0] / 100)) {
            return false;
          }

          if (filters.roi[1] !== -1 && roi > (filters.roi[1] / 100)) {
            return false;
          }
        }

        // offers
        if (filters.offers && (filters.offers[0] !== -1 || filters.offers[1] !== -1)) {
          let offersCount = item.total_offers_count;

          if (filters.offers[0] !== -1 && offersCount < filters.offers[0]) {
            return false;
          }

          if (filters.offers[1] !== -1 && offersCount > filters.offers[1]) {
            return false;
          }
        }

        // offers
        if (filters.sales && (filters.sales[0] !== -1 || filters.sales[1] !== -1)) {
          let sales = item.keepa_monthly_sold > 0 ? item.keepa_monthly_sold : item.estimate_sales_30_days;

          if (filters.sales[0] !== -1 && sales < filters.sales[0]) {
            return false;
          }

          if (filters.sales[1] !== -1 && sales > filters.sales[1]) {
            return false;
          }
        }

        // offers
        if (filters.bsr_drops_30_days && (filters.bsr_drops_30_days[0] !== -1 || filters.bsr_drops_30_days[1] !== -1)) {
          let drops = item.sales_rank_drops_30_days;

          if (filters.bsr_drops_30_days[0] !== -1 && drops < filters.bsr_drops_30_days[0]) {
            return false;
          }

          if (filters.bsr_drops_30_days[1] !== -1 && drops > filters.bsr_drops_30_days[1]) {
            return false;
          }
        }

        return true;
      });
    }

    const sortFunction = (a, b) => {
      if (filters.sortBy) {
        let getKey = (n, key) => {
          if (filters.sortBy[0] === "roi") {
            return parseFloat(calculateROI(n, filters))
          }
          if (filters.sortBy[0] === "profit") {
            return parseFloat(n.az_price - (n.price * (1 - ((filters.priceCoupon || 0) / 100))) - (filters.isFBM ? 0 : n.fba_fee) - (n.ref_fee_perc * n.az_price) - parseFloat(localStorage.getItem("costPrep") || 0))
          }
          return parseFloat(n[key])
        }
        if (filters.sortBy[1]) {
          return getKey(a, filters.sortBy[0]) - getKey(b, filters.sortBy[0])
        } else {
          return getKey(b, filters.sortBy[0]) - getKey(a, filters.sortBy[0])
        }
      } else {
        return 1
      }
    }

    setFilteredData(filterData(products, filters).sort((a, b) => sortFunction(a, b)));
  }, [filters, products]);

  if (props.isMobile) {
    return (
      <>
        <Helmet>
          <title>Nepeto 3.0 - Live Search</title>
          <meta
            name="description"
            content="Source from your favorite suppliers, automated, LIVE!"
          />
          <meta
            name="keywords"
            content="amazon fba, product sourcing, live, amazon fba products, fba products, fba sourcing"
          />
        </Helmet>
        <div>
          <h1
            style={{
              fontSize: props.isMobile ? "5vh" : "4vw",
              marginTop: props.isMobile ? "5vh" : "13vh",
              color: "#8c3e7c",
              margin: "5%"
            }}
          >
            Please log in from your desktop to use Live Search!
          </h1>
        </div>
      </>)
  }

  return (
    <>
      <Helmet>
        <title>Nepeto 3.0 - Live Search</title>
        <meta
          name="description"
          content="Source from your favorite suppliers, automated, LIVE!"
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, live, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <div className="container" style={{ marginBottom: "10vh", textAlign: "center", marginTop: "-10vh" }}>
        <h1
          style={{
            fontSize: props.isMobile ? "5vh" : "4vw",
            marginTop: props.isMobile ? "5vh" : "13vh",
            color: "#8c3e7c",
          }}
        >
          Live Search<span style={{ fontSize: "30%" }}> [Beta]</span>
        </h1>
        <h2
          style={{
            fontSize: props.isMobile ? "2vh" : "1vw",
            color: "#8c3e7c",
            width: "60%",
            margin: "auto"
          }}
        >
          Source from your favorite suppliers, automated, LIVE!<br />
          Do not wait until Nepeto's algorithm scan the specific pages you'd like to scan. Search it live here!
        </h2>
        <button className="button is-link" onClick={() => window.open("https://www.youtube.com/watch?v=1_LdLNouOyk&t=26s")}>Click here to watch a demo on how to use it!</button>
        <br /><br />
        {props.activePlanLevel !== "Master Plan" ? <p
          style={{
            width: "100%",
            margin: "auto",
            fontSize: "200%",
            boxShadow: "0px 0px 5px 1px #8c3e7c",
            padding: "2%",
          }}
        >
          To use Nepeto 3.0 Live Search, you need to have the Master Plan subscription!
          <br />
          <br />
          <button
            className="button is-rounded is-size-6-mobile is-size-6-tablet"
            style={{
              backgroundColor: "#32CD32",
              margin: "auto",
              color: "#ffffff",
            }}
            onClick={() =>
              window.location.href = "/pricing"
            }
          >
            Click here to start!
          </button>
        </p> : <>
          <div className="field is-grouped" style={{ width: '50%', marginLeft: '25%', }}>
            <p className="control is-expanded">
              <input className="input" type="text" placeholder="Paste URLs here" onChange={(e) => setInput(e.target.value)} value={input} />
            </p>
            <p className="control">
              <button className="button" onClick={handleSearch} style={{ color: 'white', backgroundColor: '#8c3e7c', }}>
                Search
              </button>
            </p>
          </div>
          <button className="button" onClick={() => setIsSupplieModalActive(true)} style={{ color: '#8c3e7c', cursor: "pointer" }}>
            Click to open a list of the supported suppliers
          </button>
          <br /><br /><br />
          <div>
            <table className="table products-table" style={{ width: "100%", margin: "auto", maxHeight: "30vh", overflowY: "scroll" }}>
              <thead>
                <tr>
                  <th style={{ width: "25vw", maxWidth: "25vw", minWidth: "25vw", }}>URL</th>
                  <th>Products Found</th>
                  <th class="tooltip">
                    Products Scanned<span style={{ fontSize: "65%" }}>(?)</span>
                    <span class="tooltiptext" style={{ marginTop: "-15%" }}>
                      This number includes Variations
                    </span>
                  </th>
                  <th>Supplier's Total Deals</th>
                  <th>Page Number</th>
                  <th style={{ width: "20%" }}>Progress</th>
                  <th style={{ width: "10%" }}>Status</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={`${row.url}-${index}`}>
                    <td style={{ width: "25vw", maxWidth: "25vw", minWidth: "25vw", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", }} className="is-center" title={row.url}>{row.url}</td>
                    <td>{row.totalURLs}</td>
                    <td>{row.productsScanned}</td>
                    <td>{((filteredData && filteredData.length > 0) ? filteredData : removeDuplicates(products)).filter(obj => obj.source === checkSupplier(row.url).supplier).length}</td>
                    <td>{row.pageNumber}</td>
                    <td>
                      <StepIndicator
                        currentStep={row.step}
                        stop={row.shouldStop}
                        loading={row.isLoading}
                        working={row.isWorking}
                      />
                    </td>
                    <td>
                      {row.shouldStop ? (
                        <span>Stopped</span>
                      ) : (
                        <>
                          {row.isLoading ? (
                            <button className="button is-danger is-small is-rounded" onClick={() => stopFetching(row.url)}>
                              STOP
                            </button>
                          ) : (
                            <span>Completed
                              <div className="tooltip" style={{ position: "absolute", marginLeft: "1.5%", marginTop: "-0.3%" }}>
                                ► <button style={{padding: 0, backgroundColor: "white", color: "red", fontSize: "1.5vw", border: "none", cursor: "pointer"}} onClick={() => window.open("https://youtu.be/U5O8kA6-2m0?si=l0AVYPAIcmIiON6v")}><i class="fa fa-youtube-play" aria-hidden="true"></i></button> ◄
                                <span class="tooltiptext" style={{ marginTop: "-15%" }}>
                                  If the live search ended earlier than expected, watch this video.
                                </span></div><br/><br/>
                            </span>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {isLoading && <tr style={{margin: "auto", display: "inline"}}>
                <td style={{ borderColor: "#E3D0DF", paddingTop: "5px" }}>
                  <progress class="progress is-small" max="100">15%</progress>
                  Matching products in progress...
                </td>
              </tr>}

            {/* <br/>
          <div style={{ display: 'flex', justifyContent: 'end', }}>
            <button className="button is-small" onClick={() => setIsSupplieModalActive(true)} style={{ color: 'white', backgroundColor: '#8c3e7c', }}>
              Add new Supplier
            </button>
            <button className="button is-small" onClick={() => setIsSupplieModalActive(true)} style={{ color: 'white', backgroundColor: '#8c3e7c', }}>
              Supported Suppliers
            </button>
          </div> */}
          </div></>}
      </div>

      <>
        {products.length === 0 ? (
          isStarted ? (<progress class="progress is-small" max="100">15%</progress>) : (<p style={{ textAlign: "center", marginBottom: '5vh' }}>No products</p>)
        ) : (
          <>
            {(filteredData.length === 0 && Object.keys(filters).length > 0) && <p style={{ textAlign: "center" }}>No products match your filters.</p>}
            <FilterBar
              data={(filteredData && filteredData.length > 0) ? filteredData : removeDuplicates(products)}
              setFilters={setFilters}
              filters={filters}
              activePlanLevel={"Master Plan"}
            />
            <ProductsTable
              data={(filteredData && filteredData.length > 0) ? filteredData : removeDuplicates(products)}
              identifier={"Barcode"}
              isMobile={props.isMobile}
              hideCredits={props.hideCredits}
              filters={filters}
              setSortASC={setSortASC}
              sortASC={sortASC}
              setFilters={setFilters}
              sellerId={props.sellerId}
              refreshToken={props.refreshToken}
              isLoading={isLoading}
            />
          </>
        )}
      </>

      <SupplierModal
        isActive={isSupplierModalActive}
        onClose={() => setIsSupplieModalActive(false)}
        suppliersList={SUPPLIERS_LIST}
        isMobile={props.isMobile}
        underMain={underMain}
      />
    </>
  );
}

export default LiveSearch;